import { Button, Container } from '@material-ui/core';
import {useHistory} from 'react-router-dom';

const ThankYou = (props: any) => {
    
    let history:any = useHistory();
    
    const redirectToProfile = () => {
        history.push('/profile')
    }

    return(
      <Container>
        Thank you for subscribing!

        <Button onClick={redirectToProfile}> Click here to go back to your profile</Button>
      </Container>
    )
}

export default ThankYou;