export const qwertyToArabicMap =  {
    "Digit0": "0",
    "Digit1": "1",
    "Digit2": "2",
    "Digit3": "3",
    "Digit4": "4",
    "Digit5": "5",
    "Digit6": "6",
    "Digit7": "7",
    "Digit8": "8",
    "Digit9": "9",
    "Minus": "-",
    "Equal": "=",
    "Backspace": "",
    "Tab": "",
    "KeyQ": "ض",
    "KeyW": "ص",
    "KeyE": "ث",
    "KeyR": "ق",
    "KeyT": "ف",
    "KeyY": "غ",
    "KeyU": "ع",
    "KeyI": "ه",
    "KeyO": "خ",
    "KeyP": "ح",
    "BracketLeft": "ج",
    "BracketRight": "ة",
    "Enter": "",
    "ControlLeft": "",
    "KeyA": "ش",
    "KeyS": "س",
    "KeyD": "ي",
    "KeyF": "ب",
    "KeyG": "ل",
    "KeyH": "ا",
    "KeyJ": "ت",
    "KeyK": "ن",
    "KeyL": "م",
    "Semicolon": "ك",
    "Quote": "",
    "Backquote": "",
    "ShiftLeft": "",
    "Backslash": "",
    "KeyZ": "ظ",
    "KeyX": "ط",
    "KeyC": "ذ",
    "KeyV": "د",
    "KeyB": "ز",
    "KeyN": "ر",
    "KeyM": "و",
    "Comma": "",
    "Period": "",
    "Slash": "",
    "ShiftRight": "",
    "Shift+Digit0": "0",
    "Shift+Digit1": "1",
    "Shift+Digit2": "2",
    "Shift+Digit3": "3",
    "Shift+Digit4": "4",
    "Shift+Digit5": "5",
    "Shift+Digit6": "6",
    "Shift+Digit7": "7",
    "Shift+Digit8": "8",
    "Shift+Digit9": "9",
    "Shift+Minus": "ـ",
    "Shift+Equal": "+",
    "Shift+Backspace": "",
    "Shift+Tab": "",
    "Shift+KeyQ": " َ",
    "Shift+KeyW": " ً",
    "Shift+KeyE": " ِ",
    "Shift+KeyR": " ٍ",
    "Shift+KeyT": " ُ",
    "Shift+KeyY": " ٌ",
    "Shift+KeyU": " ْ",
    "Shift+KeyI": " ّ",
    "Shift+KeyO": "]",
    "Shift+KeyP": "[",
    "Shift+BracketLeft": "}",
    "Shift+BracketRight": "{",
    "Shift+Enter": "",
    "Shift+ControlLeft": "",
    "Shift+KeyA": "»",
    "Shift+KeyS": "«",
    "Shift+KeyD": "ى",
    "Shift+KeyF": "",
    "Shift+KeyG": "",
    "Shift+KeyH": "آ",
    "Shift+KeyJ": "",
    "Shift+KeyK": "٫",
    "Shift+KeyL": "٬",
    "Shift+Semicolon": ":",
    "Shift+Quote": "\"",
    "Shift+Backquote": "|",
    "Shift+ShiftLeft": "",
    "Shift+Backslash": "",
    "Shift+KeyZ": "'",
    "Shift+KeyX": "",
    "Shift+KeyC": "ئ",
    "Shift+KeyV": "ء",
    "Shift+KeyB": "أ",
    "Shift+KeyN": "إ",
    "Shift+KeyM": "ؤ",
    "Shift+Comma": ">",
    "Shift+Period": "<",
    "Shift+Slash": "؟",
    "Shift+ShiftRight": ""
}
export const qwertyToArabicPhonetic =  {
    "Digit0": "0",
    "Digit1": "1",
    "Digit2": "2",
    "Digit3": "3",
    "Digit4": "4",
    "Digit5": "5",
    "Digit6": "6",
    "Digit7": "7",
    "Digit8": "8",
    "Digit9": "9",
    "Minus": "-",
    "Equal": "=",
    "Backspace": "",
    "Tab": "",
    "KeyQ": "ق",
    "KeyW": "ش",
    "KeyE": "ع",
    "KeyR": "ر",
    "KeyT": "ت",
    "KeyY": "ط",
    "KeyU": "و",
    "KeyI": "ي",
    "KeyO": "ه",
    "KeyP": "ة",
    "BracketLeft": "",
    "BracketRight": "",
    "Enter": "",
    "ControlLeft": "",
    "KeyA": "ا",
    "KeyS": "س",
    "KeyD": "د",
    "KeyF": "ف",
    "KeyG": "غ",
    "KeyH": "ح",
    "KeyJ": "ج",
    "KeyK": "ك",
    "KeyL": "ل",
    "Semicolon": "",
    "Quote": "",
    "Backquote": "",
    "ShiftLeft": "",
    "Backslash": "",
    "KeyZ": "ز",
    "KeyX": "خ",
    "KeyC": "ص",
    "KeyV": "ذ",
    "KeyB": "ب",
    "KeyN": "ن",
    "KeyM": "م",
    "Comma": "",
    "Period": "",
    "Slash": "",
    "ShiftRight": "",
    "Shift+Digit0": "",
    "Shift+Digit1": "",
    "Shift+Digit2": "",
    "Shift+Digit3": "",
    "Shift+Digit4": "",
    "Shift+Digit5": "",
    "Shift+Digit6": "",
    "Shift+Digit7": "",
    "Shift+Digit8": "",
    "Shift+Digit9": "",
    "Shift+Minus": "",
    "Shift+Equal": "",
    "Shift+Backspace": "",
    "Shift+Tab": "",
    "Shift+KeyQ": "ڤ",
    "Shift+KeyW": " ّ",
    "Shift+KeyE": "غ",
    "Shift+KeyR": "ز",
    "Shift+KeyT": "ث",
    "Shift+KeyY": "ظ",
    "Shift+KeyU": "ے",
    "Shift+KeyI": "ى",
    "Shift+KeyO": "ة",
    "Shift+KeyP": "ت",
    "Shift+BracketLeft": "",
    "Shift+BracketRight": "",
    "Shift+Enter": "",
    "Shift+ControlLeft": "",
    "Shift+KeyA": "آ",
    "Shift+KeyS": "ش",
    "Shift+KeyD": "ذ",
    "Shift+KeyF": "ق",
    "Shift+KeyG": "ع",
    "Shift+KeyH": "خ",
    "Shift+KeyJ": "چ",
    "Shift+KeyK": "گ",
    "Shift+KeyL": "❊",
    "Shift+Semicolon": "",
    "Shift+Quote": "",
    "Shift+Backquote": "",
    "Shift+ShiftLeft": "",
    "Shift+Backslash": "",
    "Shift+KeyZ": "ژ",
    "Shift+KeyX": "ح",
    "Shift+KeyC": "ض",
    "Shift+KeyV": "د",
    "Shift+KeyB": "پ",
    "Shift+KeyN": "ث",
    "Shift+KeyM": "آ",
    "Shift+Comma": "",
    "Shift+Period": "",
    "Shift+Slash": "",
    "Shift+ShiftRight":""
}
export const qwertyToRussianPhonetic =  {
    "Digit0": "0",
    "Digit1": "1",
    "Digit2": "2",
    "Digit3": "3",
    "Digit4": "4",
    "Digit5": "5",
    "Digit6": "6",
    "Digit7": "7",
    "Digit8": "8",
    "Digit9": "9",
    "Minus": "-",
    "Equal": "=",
    "Backspace": "",
    "Tab": "",
    "KeyQ": "ض",
    "KeyW": "ص",
    "KeyE": "ث",
    "KeyR": "ق",
    "KeyT": "ف",
    "KeyY": "غ",
    "KeyU": "ع",
    "KeyI": "ه",
    "KeyO": "خ",
    "KeyP": "ح",
    "BracketLeft": "ج",
    "BracketRight": "ة",
    "Enter": "",
    "ControlLeft": "",
    "KeyA": "ش",
    "KeyS": "س",
    "KeyD": "ي",
    "KeyF": "ب",
    "KeyG": "ل",
    "KeyH": "ا",
    "KeyJ": "ت",
    "KeyK": "ن",
    "KeyL": "م",
    "Semicolon": "ك",
    "Quote": "",
    "Backquote": "",
    "ShiftLeft": "",
    "Backslash": "",
    "KeyZ": "ظ",
    "KeyX": "ط",
    "KeyC": "ذ",
    "KeyV": "د",
    "KeyB": "ز",
    "KeyN": "ر",
    "KeyM": "و",
    "Comma": "",
    "Period": "",
    "Slash": "",
    "ShiftRight": "",
    "Shift+Digit0": "0",
    "Shift+Digit1": "1",
    "Shift+Digit2": "2",
    "Shift+Digit3": "3",
    "Shift+Digit4": "4",
    "Shift+Digit5": "5",
    "Shift+Digit6": "6",
    "Shift+Digit7": "7",
    "Shift+Digit8": "8",
    "Shift+Digit9": "9",
    "Shift+Minus": "ـ",
    "Shift+Equal": "+",
    "Shift+Backspace": "",
    "Shift+Tab": "",
    "Shift+KeyQ": " َ",
    "Shift+KeyW": " ً",
    "Shift+KeyE": " ِ",
    "Shift+KeyR": " ٍ",
    "Shift+KeyT": " ُ",
    "Shift+KeyY": " ٌ",
    "Shift+KeyU": " ْ",
    "Shift+KeyI": " ّ",
    "Shift+KeyO": "]",
    "Shift+KeyP": "[",
    "Shift+BracketLeft": "}",
    "Shift+BracketRight": "{",
    "Shift+Enter": "",
    "Shift+ControlLeft": "",
    "Shift+KeyA": "»",
    "Shift+KeyS": "«",
    "Shift+KeyD": "ى",
    "Shift+KeyF": "",
    "Shift+KeyG": "",
    "Shift+KeyH": "آ",
    "Shift+KeyJ": "",
    "Shift+KeyK": "٫",
    "Shift+KeyL": "٬",
    "Shift+Semicolon": ":",
    "Shift+Quote": "\"",
    "Shift+Backquote": "|",
    "Shift+ShiftLeft": "",
    "Shift+Backslash": "",
    "Shift+KeyZ": "'",
    "Shift+KeyX": "",
    "Shift+KeyC": "ئ",
    "Shift+KeyV": "ء",
    "Shift+KeyB": "أ",
    "Shift+KeyN": "إ",
    "Shift+KeyM": "ؤ",
    "Shift+Comma": ">",
    "Shift+Period": "<",
    "Shift+Slash": "؟",
    "Shift+ShiftRight": ""
}
export const qwertyToRussianMap =  {
    "Digit0": "0",
    "Digit1": "1",
    "Digit2": "2",
    "Digit3": "3",
    "Digit4": "4",
    "Digit5": "5",
    "Digit6": "6",
    "Digit7": "7",
    "Digit8": "8",
    "Digit9": "9",
    "Minus": "-",
    "Equal": "=",
    "Backspace": "",
    "Tab": "",
    "KeyQ": "ض",
    "KeyW": "ص",
    "KeyE": "ث",
    "KeyR": "ق",
    "KeyT": "ف",
    "KeyY": "غ",
    "KeyU": "ع",
    "KeyI": "ه",
    "KeyO": "خ",
    "KeyP": "ح",
    "BracketLeft": "ج",
    "BracketRight": "ة",
    "Enter": "",
    "ControlLeft": "",
    "KeyA": "ش",
    "KeyS": "س",
    "KeyD": "ي",
    "KeyF": "ب",
    "KeyG": "ل",
    "KeyH": "ا",
    "KeyJ": "ت",
    "KeyK": "ن",
    "KeyL": "م",
    "Semicolon": "ك",
    "Quote": "",
    "Backquote": "",
    "ShiftLeft": "",
    "Backslash": "",
    "KeyZ": "ظ",
    "KeyX": "ط",
    "KeyC": "ذ",
    "KeyV": "د",
    "KeyB": "ز",
    "KeyN": "ر",
    "KeyM": "و",
    "Comma": "",
    "Period": "",
    "Slash": "",
    "ShiftRight": "",
    "Shift+Digit0": "0",
    "Shift+Digit1": "1",
    "Shift+Digit2": "2",
    "Shift+Digit3": "3",
    "Shift+Digit4": "4",
    "Shift+Digit5": "5",
    "Shift+Digit6": "6",
    "Shift+Digit7": "7",
    "Shift+Digit8": "8",
    "Shift+Digit9": "9",
    "Shift+Minus": "ـ",
    "Shift+Equal": "+",
    "Shift+Backspace": "",
    "Shift+Tab": "",
    "Shift+KeyQ": " َ",
    "Shift+KeyW": " ً",
    "Shift+KeyE": " ِ",
    "Shift+KeyR": " ٍ",
    "Shift+KeyT": " ُ",
    "Shift+KeyY": " ٌ",
    "Shift+KeyU": " ْ",
    "Shift+KeyI": " ّ",
    "Shift+KeyO": "]",
    "Shift+KeyP": "[",
    "Shift+BracketLeft": "}",
    "Shift+BracketRight": "{",
    "Shift+Enter": "",
    "Shift+ControlLeft": "",
    "Shift+KeyA": "»",
    "Shift+KeyS": "«",
    "Shift+KeyD": "ى",
    "Shift+KeyF": "",
    "Shift+KeyG": "",
    "Shift+KeyH": "آ",
    "Shift+KeyJ": "",
    "Shift+KeyK": "٫",
    "Shift+KeyL": "٬",
    "Shift+Semicolon": ":",
    "Shift+Quote": "\"",
    "Shift+Backquote": "|",
    "Shift+ShiftLeft": "",
    "Shift+Backslash": "",
    "Shift+KeyZ": "'",
    "Shift+KeyX": "",
    "Shift+KeyC": "ئ",
    "Shift+KeyV": "ء",
    "Shift+KeyB": "أ",
    "Shift+KeyN": "إ",
    "Shift+KeyM": "ؤ",
    "Shift+Comma": ">",
    "Shift+Period": "<",
    "Shift+Slash": "؟",
    "Shift+ShiftRight": ""
}
export const customKeyboardTemplate =  {
    "Digit0": "0",
    "Digit1": "1",
    "Digit2": "2",
    "Digit3": "3",
    "Digit4": "4",
    "Digit5": "5",
    "Digit6": "6",
    "Digit7": "7",
    "Digit8": "8",
    "Digit9": "9",
    "Minus": "-",
    "Equal": "=",
    "Backspace": "",
    "Tab": "",
    "KeyQ": "",
    "KeyW": "",
    "KeyE": "",
    "KeyR": "",
    "KeyT": "",
    "KeyY": "",
    "KeyU": "",
    "KeyI": "",
    "KeyO": "",
    "KeyP": "",
    "BracketLeft": "",
    "BracketRight": "",
    "Enter": "",
    "ControlLeft": "",
    "KeyA": "",
    "KeyS": "",
    "KeyD": "",
    "KeyF": "",
    "KeyG": "",
    "KeyH": "",
    "KeyJ": "",
    "KeyK": "",
    "KeyL": "",
    "Semicolon": "",
    "Quote": "",
    "Backquote": "",
    "ShiftLeft": "",
    "Backslash": "",
    "KeyZ": "",
    "KeyX": "",
    "KeyC": "",
    "KeyV": "",
    "KeyB": "",
    "KeyN": "",
    "KeyM": "",
    "Comma": "",
    "Period": "",
    "Slash": "",
    "ShiftRight": "",
    "Shift+Digit0": "",
    "Shift+Digit1": "",
    "Shift+Digit2": "",
    "Shift+Digit3": "",
    "Shift+Digit4": "",
    "Shift+Digit5": "",
    "Shift+Digit6": "",
    "Shift+Digit7": "",
    "Shift+Digit8": "",
    "Shift+Digit9": "",
    "Shift+Minus": "",
    "Shift+Equal": "",
    "Shift+Backspace": "",
    "Shift+Tab": "",
    "Shift+KeyQ": " ",
    "Shift+KeyW": " ",
    "Shift+KeyE": " ",
    "Shift+KeyR": " ",
    "Shift+KeyT": " ",
    "Shift+KeyY": " ",
    "Shift+KeyU": " ",
    "Shift+KeyI": " ",
    "Shift+KeyO": "",
    "Shift+KeyP": "",
    "Shift+BracketLeft": "",
    "Shift+BracketRight": "",
    "Shift+Enter": "",
    "Shift+ControlLeft": "",
    "Shift+KeyA": "",
    "Shift+KeyS": "",
    "Shift+KeyD": "",
    "Shift+KeyF": "",
    "Shift+KeyG": "",
    "Shift+KeyH": "",
    "Shift+KeyJ": "",
    "Shift+KeyK": "",
    "Shift+KeyL": "",
    "Shift+Semicolon": "",
    "Shift+Quote": "",
    "Shift+Backquote": "",
    "Shift+ShiftLeft": "",
    "Shift+Backslash": "",
    "Shift+KeyZ": "",
    "Shift+KeyX": "",
    "Shift+KeyC": "",
    "Shift+KeyV": "",
    "Shift+KeyB": "",
    "Shift+KeyN": "",
    "Shift+KeyM": "",
    "Shift+Comma": "",
    "Shift+Period": "",
    "Shift+Slash": "",
    "Shift+ShiftRight":""
}
export const convertTemplateToRSX = (input:any) => {
    const convertedTemplate = {
        "layout": {
            "default": [`${input.Digit0} ${input.Digit1} ${input.Digit2} ${input.Digit3} ${input.Digit4} ${input.Digit5} ${input.Digit6} ${input.Digit7} ${input.Digit8} ${input.Digit9} ${input.Digit0} ${input.Minus} ${input.Equal} {bksp}`,
                        `{tab} ${input.KeyQ} ${input.KeyW} ${input.KeyE} ${input.KeyR} ${input.KeyT} ${input.KeyY} ${input.KeyU} ${input.KeyI} ${input.KeyO} ${input.KeyP} ${input.BracketLeft} ${input.BracketRight}`,
                        `{lock} ${input.KeyA} ${input.KeyS} ${input.KeyD} ${input.KeyF} ${input.KeyG} ${input.KeyH} ${input.KeyJ} ${input.KeyK} ${input.KeyL} ${input.Semicolon} ${input.Quote} ${input.Backslash} {enter}`,
                        `{shift} ${input.Backquote} ${input.KeyZ} ${input.KeyX} ${input.KeyC} ${input.KeyV} ${input.KeyB} ${input.KeyN} ${input.KeyM} ${input.Comma} ${input.Period} {shift}`,
                        `{space}`],
            "shift": [
                        `${input.Digit0} ${input.Digit1} ${input.Digit2} ${input.Digit3} ${input.Digit4} ${input.Digit5} ${input.Digit6} ${input.Digit7} ${input.Digit8} ${input.Digit9} ${input.Digit0} ${input.Minus} ${input.Equal} {bksp}`,
                        `{tab} ${input.KeyQ} ${input.KeyW} ${input.KeyE} ${input.KeyR} ${input.KeyT} ${input.KeyY} ${input.KeyU} ${input.KeyI} ${input.KeyO} ${input.KeyP} ${input.BracketLeft} ${input.BracketRight}`,
                        `{lock} ${input.KeyA} ${input.KeyS} ${input.KeyD} ${input.KeyF} ${input.KeyG} ${input.KeyH} ${input.KeyJ} ${input.KeyK} ${input.KeyL} ${input.Semicolon} ${input.Quote} ${input.Backslash} {enter}`,
                        `{shift} ${input.Backquote} ${input.KeyZ} ${input.KeyX} ${input.KeyC} ${input.KeyV} ${input.KeyB} ${input.KeyN} ${input.KeyM} ${input.Comma} ${input.Period} {shift}`,
                        `{space}`
                    ],
            }
        }
    return convertedTemplate
}
export const customKeyboardSaver = () => {   
}

export const keyboardMapDicts = {
    "qwertyToArabicMap": qwertyToArabicMap,
    "qwertyToArabicPhonetic": qwertyToArabicPhonetic,
    "qwertyToRussianMap": qwertyToRussianMap,
    "qwertyToRussianPhonetic": qwertyToRussianPhonetic,
    "rskArabicMap": convertTemplateToRSX(qwertyToArabicMap),
    "rskArabicPhonetic": convertTemplateToRSX(qwertyToArabicPhonetic),
    "rskRussian": convertTemplateToRSX(qwertyToArabicMap),
    "rskArabicCustom": convertTemplateToRSX(customKeyboardTemplate),
    "rskCustom": convertTemplateToRSX(customKeyboardTemplate)
}

export const letterArrays = {
    "Arabic": ["ا","ب","ت","ث","ج","ح","خ","د","ذ","ر","ز","س","ش","ص","ض","ط","ظ","ع","غ","ف","ق","ك","ف","م","ن","ه",",","ي","ء"]
}