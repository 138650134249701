import {useState, useEffect} from 'react';
import {IconButton, Modal, Container, Box, Typography, AppBar, Tabs, Tab} from '@material-ui/core';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import KeyboardWrapper from './KeyboardWrapper';
import {keyboardMapDicts, customKeyboardTemplate, convertTemplateToRSX, letterArrays} from './Library';
const keyboardLibrary:any = keyboardMapDicts;
const letterArrayLibrary:any = letterArrays;

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '70%',
        paddingTop: '10%',
        justifyContent: 'center',
        margin: 'auto',
        alignItems: 'center',
        color: "black",
      "& .Mui-selected":{
          color: "black !important"
      },
    },
    homePage: {
    }
    }));

export const KeyboardControl = (props:any) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const TabPanel = (props: any) => {
        const { children, value, index, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
      };
      
      function a11yProps(index: any) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    const TabView = () => {
        const classes = useStyles();
        const [value, setValue] = useState(0);
      
        const handleChange = (event: any, newValue: any) => {
          setValue(newValue);
        };
      
        const rskName:any = "rsk"+props.language
        const mapName:any = rskName+"Map"
        const customName:any = rskName+"Custom"
        const phoneticName:any = rskName+"Phonetic"

        return (
          <div className={classes.root}>
            <AppBar position="static" style={{backgroundColor: "wheat"}} >
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"  variant="fullWidth">
                <Tab label={`Standard ${props.language} Mapping`} {...a11yProps(0)} />
                <Tab label={`Phonetic ${props.language} to ${navigator.language}`} {...a11yProps(1)} />
                <Tab disabled={props.isLoggedIn} label="Custom Mapping" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <KeyboardWrapper layout={keyboardLibrary[mapName]} isCustom={false} letterArray={letterArrayLibrary[props.language]}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <KeyboardWrapper layout={keyboardLibrary[phoneticName]} isCustom={false} letterArray={letterArrayLibrary[props.language]}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <KeyboardWrapper layout={keyboardLibrary[customName]} isCustom={true} letterArray={letterArrayLibrary[props.language]}/>
            </TabPanel>
          </div>
        );
      }

      const handleOpen = () => {
          setOpen(true);
      }

      const handleClose = () => {
          setOpen(false);
      }

    return(
        <>
        <IconButton aria-label="delete" onClick={handleOpen}>
          <KeyboardIcon fontSize="medium" />
        </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
            >
            <TabView/>
        </Modal>
        </>
    )
}