import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { CircularProgress } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { useHistory, Redirect } from 'react-router-dom';
import { getStripeSubscriptionOptions } from './index';
import { useEffect, useState } from 'react';
import {t} from '../Basic';
import gql from 'graphql-tag';
import {mutations, queries} from '../../graphql';
import client from '../../APIController';
import { ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid black`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: 1,
  },
  heroContent: {
    padding: 8,
  },
  cardHeader: {
    backgroundColor: '#3873cb',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: 2,
  },
  productCard: {
      width: '400px'
  },
  footer: {
    borderTop: `1px solid black`,
    marginTop: 8,
    paddingTop: 3,
    paddingBottom: 3,
  },
  productsContainer: {
      flexGrow: 3
  },
  fullPageLoader: {
      width: '100%',
      height: '100%',
      flex: 1,
      backgroundColor: 'lightgrey',
      opacity: '70%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex !important'
  },
  loader: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex'
  },
  currentSubRoot: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
}));

const stripePromise = loadStripe('pk_test_51JF50ZKavjGDz9jG2ZapQrOi075KOjaDb9ka0I8EtiBwBsoCnRhNu3jVhZ3U4ugWbgJn0yHYW0cpTuwdYcADMPop00D24oIfSc');


const Membership = (props:any) => {

  var localeToCurrency:any = {
    'en-gb': 'GBP',
    'en-us': 'USD'
  };

  const classes = useStyles();
  const userLocaleCurrency = (localeToCurrency[navigator.language.toLowerCase()] !== undefined) ? localeToCurrency[navigator.language.toLowerCase()] : 'USD'
  const [products, setProducts] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({"clientSecret": "", "subscriptionId": ""})
  const [showLoader, setShowLoader] = useState(false);
  const [userDetails, setUserDetails] = useState({"renewalDate": "", "subscriptionID": "", "membership_status": "", "membership": ""})
  const [showCancel, setShowCancel] = useState(false);

    const createStripeCheckout = (event:any) => {
        setShowLoader(true)
        const customerDetails:any = client.mutate({
            mutation: gql(mutations.createStripeCheckoutSession),
            variables: {"price": event.target.id}
        })
        .then((data:any) => {
            setSubscriptionData({"clientSecret": data["data"]["createStripeCheckoutSession"]["clientSecret"], "subscriptionId": data["data"]["createStripeCheckoutSession"]["subscriptionId"]})
        })
        .catch((error) => {
        })
    
        return customerDetails
    }

    const checkIfSubscribedUser = () => {
      const user:any = client.query({
        query: gql(queries.getUserDetails),
        fetchPolicy: 'network-only'
      })
      user.then((data:any) => {
        setUserDetails({"renewalDate": data.data.getUserDetails.renewalDate, "subscriptionID": data.data.getUserDetails.subscriptionID, "membership_status": data.data.getUserDetails.membershipStatus, "membership": data.data.getUserDetails.membership})
      })
    }


    useEffect(() => {   
        
        const products = getStripeSubscriptionOptions(userLocaleCurrency, "subscription")
        products.then((data:any) => {
            const products = data["data"]["getStripeSubscriptionOptions"]
            const sortedProducts = products.sort((a:any,b:any) => (a.unit_amount > b.unit_amount) ? 1 : ((b.unit_amount > a.unit_amount) ? -1 : 0))
            setProducts(sortedProducts)
        })
        checkIfSubscribedUser();
          return () => {
        }
    }, []);

  function formatAsCurrency(value:number, currency:string) {
    var currencyCode:string = localeToCurrency[currency.toLowerCase()];
  
    return value.toLocaleString(currencyCode, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2
    });
  };

  if(subscriptionData.clientSecret.length > 2 && subscriptionData.subscriptionId.length > 2) {
    return <Redirect to={{
      pathname: '/checkout',
      state: subscriptionData
    }} />
    setShowLoader(false)
  }

  const SubscribedUser = () => {
    return(
      <Container>
        {(userDetails.membership === 'month') ?
         <>
         <CurrentSubscription/>
         <UpgradeSubscription/>
         </>
          :
          <>
          <CurrentSubscription/>
          </>
        }
      </Container>
    )
  }

  const UpgradeSubscription = () => {
    const yearProduct:any = products.filter((element:any, index) => element.recurring.interval === 'year')
    return(
      <Grid container spacing={5} className={classes.productsContainer} alignItems="flex-end">
      {yearProduct.map((product:any) => (
        <Grid item key={product.nickname} xs={12} sm={product.nickname === 'Enterprise' ? 12 : 6} md={4} className={classes.productCard}>
          <Card>
            <CardHeader
              title={product.nickname}
              titleTypographyProps={{ align: 'center' }}
              subheaderTypographyProps={{ align: 'center' }}
              action={product.recurring.interval === 'month' ? <StarIcon style={{color: 'gold'}}/> : null}
              className={classes.cardHeader}
            />
            <CardContent>
              <div className={classes.cardPricing}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  {formatAsCurrency(product.unit_amount/100, navigator.language.toLowerCase())}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {(product.recurring.interval === "year")? "/yr" :"/mo"}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
            <Button fullWidth color="primary" name={product.nickname} id={product.id} onClick={createStripeCheckout}> upgrade </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
    )
  }

  const displayCancel = () => {
    setShowCancel(true)
  }

  const hideCancel = () => {
    setShowCancel(false);
  }

  const cancelSubscription = () => {
    const user:any = client.mutate({
      mutation: gql(mutations.cancelStripeSubscription),
      fetchPolicy: 'no-cache'
    })
    user.then((data:any) => {
    })
  }

  const CurrentSubscription = () => {

    return !showCancel ? 
    (    
      <Container>
        <Card className={classes.currentSubRoot} variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Current Subscription
            </Typography>
            <Typography variant="h5" component="h2">
              Price: {}
            </Typography>
            <Typography variant="body2" component="p">
              Renewal Date: {userDetails.renewalDate}
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={displayCancel} size="small">Cancel</Button>
          </CardActions>
        </Card>
      </Container>
    ) 
    :
    (
      <Container>
      <Card className={classes.currentSubRoot} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Cancel Subscription
          </Typography>
          <Typography variant="h5" component="h2">
            Price: {}
          </Typography>
          <Typography variant="body2" component="p">
            Renewal Date: {userDetails.renewalDate}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={cancelSubscription} size="small">Cancel Subscription</Button>
          <Button onClick={hideCancel} size="small">Go Back</Button>
        </CardActions>
      </Card>
    </Container> 
    )
  }

  if(userDetails.membership_status !== 'paid') {
    return (
    <Elements stripe={stripePromise}>
      {!showLoader && products.length > 0? 
      <>
      <CssBaseline />
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Pricing
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} className={classes.productsContainer} alignItems="flex-end">
          {products.map((product:any) => (
            <Grid item key={product.nickname} xs={12} sm={product.nickname === 'Enterprise' ? 12 : 6} md={4} className={classes.productCard}>
              <Card>
                <CardHeader
                  title={product.nickname}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={product.recurring.interval === 'month' ? <StarIcon style={{color: 'gold'}}/> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {formatAsCurrency(product.unit_amount/100, navigator.language)}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      {(product.recurring.interval === "year")? "/yr" :"/mo"}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions>
                <Button fullWidth color="primary" name={product.nickname} id={product.id} onClick={createStripeCheckout}> subscribe </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container> </>
      : 
      <Container maxWidth={"xl"} className={classes.fullPageLoader}> <CircularProgress className={classes.loader}/></Container>}
    </Elements>
  )
  }
  return(
    <>
    <SubscribedUser/>
    </>
  )
}

export default Membership;