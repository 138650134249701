import { StylesDictionary } from '../../TypeScriptInterfaces';
import '../../App.css';
import {t} from '../Basic';
import {Container} from '@material-ui/core';

const styles:StylesDictionary = {
    root: {
        backgroundColor: 'transparent'
    },
    scoreHeader: {
        color: 'black',

    },
    circleNormal: {
        width: '5em',
        height: '5em',
        margin: '20px',
        borderRadius: '50%',
        backgroundColor: '#47B71A',
        border: '4px solid #fff',
        boxShadow: '0 0 0 5px #47B71A, 0 0 0 10px white',
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '20px',
        display: 'flex',
        justifyContent: 'center', 
        alignContent: 'center',
        flexDirection: 'column'
    },
  };

const Scoring = (props: any) => {
    
        return(
        <Container key={props.score} className="scoringContainerAnimated" style={styles.circleNormal}>
            {props.score}
        </Container>
        )
    }

export default Scoring;