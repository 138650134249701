import { useState, useEffect } from 'react';
import './App.css';
import Home from './Pages/Home';
import Amplify, {Hub} from 'aws-amplify';

import {Authenticator} from 'aws-amplify-react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthWrapper from './Pages/Auth';
import Profile from './Pages/Profile';
import Checkout from './Components/Membership/Checkout';
import ThankYou from './Pages/ThankYou';
//import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import {RecoilRoot} from 'recoil';

const aws_exports = {
  "aws_project_region": "eu-west-2",
  "aws_appsync_graphqlEndpoint": "https://2xpd5igpmnbp3i3o27bnnrzhue.appsync-api.eu-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-ododwi25ifamtkd3kxukt7auge",
  "aws_cognito_region": "eu-west-2",
  "aws_user_pools_id": "eu-west-2_X7oEjFcGr",
  "aws_user_pools_web_client_id": "4tl1s8hsastcramstmqbqtr1ko"
  } 


// const analyticsConfig = {
//   AWSPinpoint: {
//         appId: '85d1daa537544a75b86bafc8ff5f2b08',
//         region: 'us-east-1',
//         mandatorySignIn: false,
//   }
// }

const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-west-2:744b004c-7267-49c3-89d4-fb4941311d34',
    region: 'eu-west-2'
  }
}

const App = () => {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [language, setLanguage] = useState("Select language");
  const [userSub, setUserSub] = useState('');
  const [authState, setAuthState] = useState('');
  const [userMessage, setUserMessage] = useState({
    "type": "",
    "content": ""
  })
  const [subscriptionId, setSubscriptionId] = useState('');
  const [clientSecret, setClientSecret] = useState('')

  const setDefinedLanguage = (language:string) => {
    setLanguage(language)
  }

  useEffect(() => {
    Amplify.configure(aws_exports);
    Auth.configure(amplifyConfig);
    //Analytics.configure(analyticsConfig)
      let checkUser = () => {
        try {
        let user = Auth.currentAuthenticatedUser()
        user.then((data:any) => {
          setIsLoggedIn(true)
        })
        user.catch((error) => {
          setIsLoggedIn(false)
        })
      }
      catch (error) { 
        setIsLoggedIn(false)
      } 
    }
      Hub.listen('auth', checkUser)
      checkUser()
      return () => Hub.remove('auth', checkUser)
    }, []);

  return (
    <Authenticator hideDefault={true} amplifyConfig={aws_exports}>
      <RecoilRoot>
        <div className="App"> 
          <Router>
            <Switch>
              <Route exact={true} path="/" component={() => <Home isLoggedIn={isLoggedIn} language={language} setDefinedLanguage={setDefinedLanguage} setIsLoggedIn={setIsLoggedIn} setAuthState={setAuthState} userMessage={userMessage} setUserMessage={setUserMessage}/> } />
              <Route exact={true} path="/auth" component={() => <AuthWrapper isLoggedIn={isLoggedIn} language={language} setDefinedLanguage={setDefinedLanguage} setIsLoggedIn={setIsLoggedIn} userSub={userSub} setUserSub={setUserSub} authState={authState} setAuthState={setAuthState} userMessage={userMessage} setUserMessage={setUserMessage}/> } />
              <Route exact={true} path="/profile" component={isLoggedIn ? () => <Profile isLoggedIn={isLoggedIn} language={language} setDefinedLanguage={setDefinedLanguage} setIsLoggedIn={setIsLoggedIn} setAuthState={setAuthState} clientSecret={clientSecret} subscriptionId={subscriptionId} setSubscriptionId={setSubscriptionId} setClientSecret={setClientSecret}/> : () => <AuthWrapper isLoggedIn={isLoggedIn} language={language} setDefinedLanguage={setDefinedLanguage} setIsLoggedIn={setIsLoggedIn} userMessage={userMessage} setUserMessage={setUserMessage} setAuthState={setAuthState}/>} />
              <Route exact={true} path="/checkout" component={() => <Checkout clientSecret={clientSecret} subscriptionId={subscriptionId} setSubscriptionId={setSubscriptionId} setClientSecret={setClientSecret} />} />
              <Route exact={true} path="/thankyou" component={() => <ThankYou/>} />
            </Switch>
          </Router>
        </div>
        </RecoilRoot>
    </Authenticator>
  );
}

export default App;
