import {useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {t} from '../Basic';
import {useLocation, useHistory} from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import { Elements} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51JF50ZKavjGDz9jG1bW1u6CSgB4Q0nXe4QqSfynUHxZnzgpSYLUeywcPLIfzreXEJiGJ6vDZDzKiuiy9TF4akyr500osMO3g6l');

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
    }
  ]
};


const Checkout = (props:any) => {
//   const stripe = useStripe();
//   const elements = useElements();
  let location:any = useLocation();
  const [clientSecret] = useState(location.state.clientSecret);
  const [name, setName] = useState('Jenny Rosen');
  const [messages, _setMessages] = useState('');
  const [errorObject, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  let history:any = useHistory();

    
  const redirectToThankYou = () => {
      history.push('/thankyou')
    }
  //const [clientSecret] = useState(location.state.clientSecret);
  //const [subscriptionId] = useState(location.state.subscriptionId);
//   const [name, setName] = useState('Jenny Rosen');
//   const [messages, _setMessages] = useState('');
//   const [paymentIntent, setPaymentIntent] = useState();
const setMessage = (message:any) => {
    _setMessages(`${messages}\n\n${message}`);
  }

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return (
        <>
        </>
    );
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e:any) => {
    e.preventDefault();

    setProcessing(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement:any = elements.getElement(CardElement);

    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name: name,
                }
              }
            });
        if(error) {
                // show error and collect new card details.
                setProcessing(false);
                setMessage(error.message);
                return(
                    <>
                    </>
                );
            }
        
        if(paymentIntent && paymentIntent.status === 'succeeded') {
            redirectToThankYou();
        }
  }

  const SubmitButton = ({ processing, error, children, disabled }:any) => (
    <button
      className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? "Processing..." : children}
    </button>
  );  

  return(
    <>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
      <h1>Subscribe</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Full name
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </label>

        <CardElement />
        <SubmitButton processing={processing} error={errorObject} disabled={!stripe}>
            Subscribe
        </SubmitButton>

        <div>{messages}</div>
      </form>
      </Elements>
    </>
  )
}

export default Checkout;