import { Auth } from 'aws-amplify';
import TopMenuBar from '../Basic/Header';
import {Avatar, Button, CssBaseline, TextField, Typography, Container} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/styles';
import {t} from '../Basic';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: 1,
      backgroundColor: 'white',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: 1,
    },
    submit: {
      margin: 3,
    },
  }));

export const TwoFactorSetupConfirmation = () => {
    return(
        <>
        </>
    )
}

export const LoginTwoFactor = () => {
    return(
        <>
        </>
    )
}

export const EmailVerification = (props:any) => {

    const classes = useStyles();

    const sendVerification = (codeValue:any) => {
        if(codeValue.length === 6){
            const response = Auth.confirmSignUp(props.userSub, codeValue)
            
            response.then((data) => {
                if(data === 'SUCCESS'){
                    props.setAuthState()
                }
            })
            .catch((error) => {
            })
        }
    }

    return(
        <>
        <TopMenuBar setLoggedLanguage={props.setDefinedLanguage} language={props.language} setAuthState={props.setAuthState} setUserMessage={props.setUserMessage}/>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
             Verify Email
            </Typography>
            <Container>
            <TextField
                variant="outlined"
                margin="normal"
                disabled
                fullWidth
                id="username"
                label="Username"
                name="username"
                value={props.userSub}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Code"
                name="code"
                onChange={(event) => sendVerification(event.target.value)}
                autoComplete="username"
                autoFocus
              />
              <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={sendVerification}
              >
                Verify
              </Button>

            </Container>
          </div>
        </Container>
        </>
    )
}
