import {Container, CircularProgress} from '@material-ui/core';
import {useState, useEffect} from 'react';
import {queries} from '../../graphql';
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { TopMenuBar } from '../Basic';
import Conjugator from './Conjugator';
import Scoring from '../Gamification/Scoring';
import { StylesDictionary } from '../../TypeScriptInterfaces';
import WordLoader from '../Basic/WordLoader';

const styles:StylesDictionary = {
    scoreCircle:{
      animation: "popout 1s ease",
    },
  };

  function getRandom(arr:Array<any>, n:number) {
    try{
      var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
  if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
    }
    catch(error){
    }
}

const Grammar = (props:any) => {

  const filterWords = (loadedWords:any) => {
    try{
      getRandom(loadedWords, 5)?.filter(Boolean)
    }
    catch{
    }
  }

    const [loadedWords, setLoadedWords] = useState([{"word": "", "translation": "", "partOfSpeech":""}])
    const [score, setScore] = useState(0);
    const [filteredWords, setFilteredWords] = useState(filterWords(loadedWords))
    
    useEffect(() => {
        return () => {
        }
    }, []);

    return(
      <>
      <WordLoader loadedWords={loadedWords} setLoadedWords={setLoadedWords} language={props.language}/>
      {      loadedWords.length > 1 ?
        <Container>
            <Conjugator filteredWords={filteredWords} isLoggedIn={props.isLoggedIn} setScore={setScore} score={score} language={props.language} words={loadedWords} firstWord={loadedWords[0]}/>
            <Container style={styles.scoringContainerAnimated}>
                <Scoring score={score}/>
          </Container>
        </Container>
          :
          <>
          </>}
      </>
    )
}

export default Grammar;