import {AppBar, Box, Toolbar, MenuItem, Typography, Button, Select, Container} from '@material-ui/core';
import {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import InputBase from '@material-ui/core/InputBase';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import {t} from '../Basic';
import * as RECOIL from '../../Recoil';
import logo from '/hexapla-logos_white.png';

import {useSetRecoilState} from 'recoil';

const useStyles = makeStyles( theme => ({
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    select: {
      outline: 'none',
      cursor: 'pointer',
      textAlign: 'left',
      color: 'white',

    },
    selectdisabled: {
      color: 'white',
    },
    menuitem: {
      direction: "rtl",
    },
    siteNameTitle: {
      textAlign: 'left',
      color: 'transparent',
      backgroundColor: 'transparent'
    },
    menuitemhidden: {
      display: "none"
    },
    logo: {
      maxWidth: '160px',
      marginLeft: '30px',
      marginTop: '15px',
      marginBottom: '10px',
      color: 'white'
    },
    rightAlign: {
      marginLeft: "auto !important",
      marginRight: "0 !important",
      width: 'unset !important'
    },
    header: {
      backgroundColor: '#E87121 !important',
      opacity: '99%',
      width: 'unset !important'
    }
  })); 
  

const TopMenuBar = (props:any) => {
    const classes = useStyles();
    const [value, setValue] = useState("Select language");
    const [showPlaceholder, setShowPlaceholder] = useState(value === "Select language");
    const setSelectedLanguage = useSetRecoilState(RECOIL.selectedLanguage)
    let history:any = useHistory();

    const handleChange = (event: any) => {
        props.setLoggedLanguage(event.target.value);
        setValue(event.target.value);
        setSelectedLanguage(event.target.value);
        redirectToHome()
      };

    const redirectToHome = () => {
        history.push('/')
    }

    const triggerSignOut = () => {
      Auth.signOut();
      props.setIsLoggedIn(false)
    }

    const goToSignIn = () => {
      props.setUserMessage({"type": "","content": ""})
      props.setAuthState("signin")
    }

    const goToSignUp = () =>{
      props.setAuthState("signup")
      props.setUserMessage({"type": "","content": ""})
    }

    ///</Link> </Toolbar>className={classes.siteNameTitle}>
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar  className={classes.header} position="static">
          <Toolbar>
          <Link to="/">
          <img src="./hexapla-logos_white_cropped.png" alt="logo" className={classes.logo} />
          </Link> 
            {/* <Typography className={classes.siteNameTitle} variant="h6" component="div" sx={{ flexGrow: 1 }}> */}
              {/* <Link to="/">
                <Image style={{height: '100px', width: '100px'}} src={'./hexapla-logos_white.png'}/>
              </Link> */}
            {/* </Typography> */}
            <Container className={classes.rightAlign}>
            <Select
                value={props.language}
                defaultValue="Select language"
                input={<InputBase />}
                style={{color: 'white'}}
                onChange={(e) => handleChange(e)}
                onFocus={(e) => setShowPlaceholder(false)}
                onClose={(e) => setShowPlaceholder(e.target === undefined)}
                className={clsx(classes.select, value === "Select language" ? classes.selectdisabled : null)}
            >
                <MenuItem className={clsx(classes.menuitem, !showPlaceholder ? classes.menuitemhidden : null)} key="0" disabled value="Select language" >Select language</MenuItem>
                <MenuItem className={classes.menuitem} key="1" value="arabic" >Arabic</MenuItem>
                {/* <MenuItem disabled className={classes.menuitem} key="2" value="Chinese" >Chinese</MenuItem>
                <MenuItem disabled className={classes.menuitem} key="3" value="English" >English</MenuItem>
                <MenuItem disabled className={classes.menuitem} key="4" value="French" >French</MenuItem>
                <MenuItem disabled className={classes.menuitem} key="5" value="German" >German</MenuItem>
                <MenuItem disabled className={classes.menuitem} key="6" value="Italian" >Italian</MenuItem>
                <MenuItem disabled className={classes.menuitem} key="7" value="Japanese" >Japanese</MenuItem>
                
                <MenuItem disabled className={classes.menuitem} key="8" value="Portuguese" >Portuguese</MenuItem>
                <MenuItem disabled className={classes.menuitem} key="9" value="Russian" >Russian</MenuItem>
                <MenuItem disabled className={classes.menuitem} key="10" value="Spanish" >Spanish</MenuItem>
                <MenuItem disabled className={classes.menuitem} key="11" value="Turkish" >Turkish</MenuItem> */}
            </Select>
            {(props.isLoggedIn) ? 
            <>
            <Button color="inherit"><Link to={{pathname:'/profile', state: {"type": "loggedin"}}}> Profile </Link></Button>
            <Button color="inherit" onClick={triggerSignOut}>Sign Out</Button>
            </>
            :
            <>
            <Button color="inherit"><Link to={{pathname:'/auth' }} onClick={goToSignIn}> Login </Link></Button>
            <Button color="inherit"><Link to={{pathname:'/auth' }} onClick={goToSignUp}> Sign Up</Link></Button>
            </>
            }
            </Container>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }

export default TopMenuBar;