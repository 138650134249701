import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {t} from '../Basic';
import { InitiateSpeaking } from '../ReadingListeningSpeaking';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const WordCard = (props:any) => {
  const classes = useStyles();  

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Word Info:
        </Typography>
        <Typography variant="h5" component="h2">
        {props.word}
        </Typography>
        <InitiateSpeaking audio={props.audio} playAudio={props.playAudioRef}/>
        <Typography className={classes.pos} color="textSecondary">
         Translation:   {props.translation}
        </Typography>
        <Typography variant="body2" component="p">
         Part of speech:   {props.partOfSpeech} 
        </Typography>
      </CardContent>
      <CardActions>
        {props.isLoggedIn ? null : <Typography>You need to be logged in</Typography>}
        <Button disabled={!props.isLoggedIn} size="small">Save</Button>
        <Button disabled={!props.isLoggedIn} size="small">Hide</Button>
      </CardActions>
    </Card>
  );
}

export default WordCard;