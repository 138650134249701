const Arabic = {
    "Verbs": {
        "Tense": [
            "present/future",
            "past",
        ],
        "Person": [
            "first",
            "second",
            "third"
        ],
        "Gender": [
            "masculine",
            "feminine"
        ],
        "Number":
        [
            "singular", 
            "plural",
            "dual"
        ]
    }
}

const Turkish = {
    "Verbs": {
        "Tense": [
            "aorist",
            "gerund",
            "future",
            "simple past",
            "mis past",
            "past continuous",
            "present indefinite",
            "present continuous indefinite",
            "future indefinite",
            "potential"
        ],
        "Person": [
            "first",
            "second",
            "third"
        ],
        "Type": [
            "positive",
            "negative"
        ],
        "Number":
        [
            "singular", 
            "plural",
        ]
    }
}

const Russian = {
    "Verbs": {
        "Tense": [
            "present",
            "imperfect",
            "conditional",
            "past"
        ],
        "Person": [
            "first",
            "second",
            "third"
        ],
        "Gender": [
            "masculine",
            "feminine",
            "neuter"
        ],
        "Number":
        [
            "singular", 
            "plural"
        ],
        "Type": 
        [
            "participle",
            "finite",
            "imperative",
        ],
        "Mood":
        [
            "active",
            "passive",
            "adverbial"
        ]
    }
}

const LanguageOptions:any = {
    "arabic": Arabic,
    "russian": Russian,
    "turkish": Turkish
}

interface LanguageTenses {
    Tense: Array<string>
    Person: Array<string>
    Gender: Array<string>
    Number: Array<string>
}

interface LanguageOptionGroups {
    Verbs: LanguageTenses
}
interface LanguageOptionsType {
    arabic: string
}


export default LanguageOptions