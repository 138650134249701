import AWS from "aws-sdk";
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import TopMenuBar from '../Basic/Header';
import {Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Alert, Typography, Container, CircularProgress} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/styles';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import {t} from '../Basic';

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: 1,
      backgroundColor: 'white',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: 1,
    },
    submit: {
      margin: 3,
    },
  }));

const SignIn = (props:any) => {
    let history:any = useHistory();
    
    const redirectToHome = () => {
        history.push('/')
      }

    const [submitting, setSubmitting] = useState(false);
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    const sendSignIn = (event:any) => {
        setSubmitting(true);
        Auth.signIn({
            username: username,
            password: password
        })
        .then((data) => {
            if (data.signInUserSession.accessToken){
                props.setIsLoggedIn(true);
                redirectToHome();
            }
        })
        .catch((error) => {
            props.setUserMessage({
                "type": "error",
                "content": t(error.message)
            })
            setSubmitting(false)
        })
    }

    const responseGoogle = (authResult:any) => {
        if (authResult['tokenId']) {
            // Add the Google access token to the Amazon Cognito credentials login map.
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
               IdentityPoolId: 'eu-west-2:744b004c-7267-49c3-89d4-fb4941311d34',
               Logins: {
                  'accounts.google.com': authResult['tokenId']
               }
            });
        }
    }

    return(
        <>
    <TopMenuBar setLoggedLanguage={props.setDefinedLanguage} language={props.language} setAuthState={props.setAuthState} setUserMessage={props.setUserMessage}/>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Container className={classes.paper}>
          {props.userMessage && props.userMessage.type && <Alert severity={props.userMessage.type}>{t(props.userMessage.content)}</Alert>}
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("Sign in")}
        </Typography>
        <Container>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            onChange={(event) => setUsername(event.target.value)}
            autoComplete="username"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(event) => setPassword(event.target.value)}
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
            { !submitting ? <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={sendSignIn}>
            {t("Sign In")}
          </Button>: <CircularProgress />}
          <GoogleLogin
            clientId="487567207309-vpq1bnkbsg2mvf2782dimbf2ri7ekj0o.apps.googleusercontent.com"
            buttonText={t("Sign In With Google")}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
        />
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                {t("Forgot password?")}
              </Link>
            </Grid>
            <Grid item>
              <Link style={{color: 'black'}} href="#" variant="body2">
                {t("Don't have an account? Sign Up")}
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Container>
    </>
    )
}

export default SignIn