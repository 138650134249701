import { useState } from 'react';
import {Container, Slider, Button, CircularProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import { queries } from '../../graphql';
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import {normalizeArabicText, normalizeRussianText} from '../Basic/Normalisers';

interface Props {
    children: React.ReactElement;
    open: boolean;
    value: number;
  }

const wordLoaderPoints = [
    {
      value: 10,
      label: '10 Words',
    },
    {
      value: 20,
      label: '20 Words',
    },
    {
      value: 40,
      label: '40 Words',
    },
    {
      value: 60,
      label: '60 Words',
    },
    {
        value: 80,
        label: '80 Words',
    },
    {
        value: 100,
        label: '100 Words',
    }
  ];


const wordLoaderRange = [
  {
    value: 0,
    label: 'Most Frequent Words',
  },
    {
      value: 500,
      label: '500',
    },
    {
      value: 1000,
      label: '1000',
    },
    {
      value: 2000,
      label: '2000',
    },
    {
      value: 3000,
      label: '3000',
    },
    {
        value: 4000,
        label: '4000',
    },
    {
        value: 5000,
        label: 'Less Frequent Words',
    }
  ];


  function valuetext(value: number) {
    return `${value}°C`;
  }
  


  const WordRangeSelection = withStyles({
    root: {
      color: '#3a8589',
      height: 3,
      padding: '13px 0',
    },
    thumb: {
      height: 27,
      width: 27,
      backgroundColor: '#fff',
      border: '1px solid currentColor',
      boxShadow: '#ebebeb 0 2px 2px',
      '&:focus, &:hover, &$active': {
        boxShadow: '#ccc 0 2px 3px 1px',
      },
      '& .bar': {
        // display: inline-block !important;
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    active: {},
    track: {
      height: 3,
    },
    rail: {
    },
  })(Slider);

  function getRandom(arr:Array<any>, n:number) {
    try{
      var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
  if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
    }
    catch(error){
    }
}


const WordLoader = (props:any) => {

    const [wordRange, setWordRange] = useState<number[]>([0,250])
    const [wordQuantity, setWordQuantity] = useState<number>(25)
    const [loadedWords, setLoadedWords] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const handleChange = (event: any, newValue: any) => {
      if(event.target.name === "wordRangeSelection"){
        setWordRange([newValue[0], newValue[1]]);
      }
      if(event.target.name === "wordQuantity"){
          setWordQuantity(newValue)
        }
      };

      const fetchWords = () => {
        setShowLoader(true);
        try {
          const response:any = GraphQLAPI.graphql({
            query: queries.getWordBatch,
            variables: {'language': props.language.toLowerCase(), 'lower_range': wordRange[0], 'upper_range': wordRange[1]},
            authMode: GRAPHQL_AUTH_MODE.API_KEY
          })
          response.then((data:any) => {
            switch(props.language.toLowerCase()){
                  case "arabic":
                    try{
                      props.setRtl(true)
                      props.setCollatorCode('ar')
                    }
                    catch{

                    }
                    var foreignDict = data.data.getWordBatch.map(function(a:any) {return {"word": a.word.foreignWord, "normalisedWord": normalizeArabicText(a.word.foreignWord), "translation": a.word.translation, "partOfSpeech": a.word.partOfSpeech, "audio": a.audio}})
                    for (let i = foreignDict.length - 1; i > 0; i--) {
                      const j = Math.floor(Math.random() * (i + 1));
                      [foreignDict[i], foreignDict[j]] = [foreignDict[j], foreignDict[i]];
                    }
                    props.setLoadedWords(foreignDict);
                    try{
                      props.setLoadedForeignWord(foreignDict[0].normalisedWord)
                    }
                    catch{

                    }
                    setShowLoader(false)
                    break;
                  case "russian":
                    try{
                      props.setRtl(false)
                      props.setCollatorCode('ru')
                    }
                    catch{

                    }
                    var foreignDict = data.data.getWordBatch.map(function(a:any) {return {"word": a.word.foreignWord, "normalisedWord": normalizeRussianText(a.word.foreignWord), "translation": a.word.translation, "partOfSpeech": a.word.partOfSpeech, "audio": a.audio}})
                    for (let i = foreignDict.length - 1; i > 0; i--) {
                      const j = Math.floor(Math.random() * (i + 1));
                      [foreignDict[i], foreignDict[j]] = [foreignDict[j], foreignDict[i]];
                    }
                    props.setLoadedWords(foreignDict);
                    try{
                      props.setLoadedForeignWord(foreignDict[0].normalisedWord)
                    }
                    catch{

                    }
                    setShowLoader(false)
                    break;
                  case "turkish":
                      var foreignDict = data.data.getWordBatch.map(function(a:any) {return {"word": a.word.foreignWord, "translation": a.word.translation, "partOfSpeech": a.word.partOfSpeech, "audio": a.audio}})
                      foreignDict = foreignDict.filter((element:any, index:number) => element.partOfSpeech === 'v' && element.word.search('/') === -1)
                      for (let i = foreignDict.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [foreignDict[i], foreignDict[j]] = [foreignDict[j], foreignDict[i]];
                      }
                      setShowLoader(false)
                      props.setLoadedWords(foreignDict);
                    break;
                  default:
                  }
          })
        } catch (error) {
        }
      };

      const ResetWords = () => {
        props.setLoadedWords([])
      }

    return (
      <>
        <Container>
            <Slider
                defaultValue={25}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-always"
                step={10}
                min={10}
                marks={wordLoaderPoints}
                valueLabelDisplay="on"
                onChange={handleChange}
                name="wordQuantity"
            />
            <WordRangeSelection
                getAriaLabel={(index) => (index === 0 ? 'Most Common Words' : 'Least Common Words')}
                defaultValue={[0, 250]}
                marks={wordLoaderRange}
                min={0}
                max={5000}
                name="wordRangeSelection"
                onChange={handleChange}
            />
        </Container>
        <Container>
          {props.loadedWords.length > 1 ? 
          <Container>
            {
              <Button onClick={ResetWords}>
                Reset
             </Button>
            }
          </Container>
            :
            <>
          {showLoader ? 
            <>
            <CircularProgress/>
          </>
          : 
          <Button onClick={fetchWords}>
              Start
          </Button>
          }
          </>
          }
        </Container>
        </>
    );
}

export default WordLoader;