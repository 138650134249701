/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const cancelStripeSubscription = /* GraphQL */ `
  mutation CancelStripeSubscription {
    cancelStripeSubscription
  }
`;
export const createStripeCheckoutSession = /* GraphQL */ `
  mutation CreateStripeCheckoutSession($customerID: String, $price: String) {
    createStripeCheckoutSession(customerID: $customerID, price: $price) {
      clientSecret
      subscriptionId
    }
  }
`;
export const createStripeCustomer = /* GraphQL */ `
  mutation CreateStripeCustomer {
    createStripeCustomer
  }
`;
export const putStripeSessionId = /* GraphQL */ `
  mutation PutStripeSessionId($email: String, $sessionId: String) {
    putStripeSessionId(email: $email, sessionId: $sessionId)
  }
`;
export const putUserDetails = /* GraphQL */ `
  mutation PutUserDetails(
    $firstName: String
    $lastName: String
    $membershipStatus: String
    $username: String
  ) {
    putUserDetails(
      firstName: $firstName
      lastName: $lastName
      membershipStatus: $membershipStatus
      username: $username
    ) {
      email
      firstName
      lastName
      membership
      membershipStatus
      renewalDate
      startDate
      stripeId
      subscriptionID
      username
    }
  }
`;
export const putUserKeyboard = /* GraphQL */ `
  mutation PutUserKeyboard($keyboardArray: String, $language: String) {
    putUserKeyboard(keyboardArray: $keyboardArray, language: $language)
  }
`;
export const putUserWordList = /* GraphQL */ `
  mutation PutUserWordList($language: String, $wordlist: [String]) {
    putUserWordList(language: $language, wordlist: $wordlist)
  }
`;
export const stripeWebhookEndpoint = /* GraphQL */ `
  mutation StripeWebhookEndpoint {
    stripeWebhookEndpoint
  }
`;
