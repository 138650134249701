import {useState} from 'react';
import {Avatar, Alert, Button, CircularProgress} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import TopMenuBar from '../Basic/Header';
import { Auth } from 'aws-amplify';
import validator from 'validator';
import {t} from '../Basic';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 1,
    backgroundColor: 'white',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 3,
  },
  submit: {
    margin: 3,
  },
}));

const SignUp = (props:any) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');  
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('')
  const [submitting, setSubmitting] = useState(false);

const sendSignUp = () => {
    if(email.length > 1 && validator.isEmail(email) === false) {
        props.setUserMessage({
            "type": "error",
            "content": "It looks like your email isn't formatted correctly, please double check it."
        })
    return;
    }

    if(emailConfirm.length > 1 && email !== emailConfirm){
        props.setUserMessage({
            "type": "error",
            "content": "Oops your emails don't match, please double check"
        })
    return;
    }

    const passwordOptions:any = { minLength: 6, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1, returnScore: false, pointsPerUnique: 1, pointsPerRepeat: 0.5, pointsForContainingLower: 10, pointsForContainingUpper: 10, pointsForContainingNumber: 10, pointsForContainingSymbol: 10 }

    if(password.length > 1 && validator.isStrongPassword(password, passwordOptions) === false){
        props.setUserMessage({
            "type": "error",
            "content": "Oops your passwords isn't strong enough. It needs to contain at least 6 characters, 1 lowercase & 1 uppercase letter, 1 number and 1 symbol."
        })
    return;
    }

    if(passwordConfirm.length > 1 && password !== passwordConfirm){
        props.setUserMessage({
            "type": "error",
            "content": "Oops your passwords don't match, please double check"
        })
    return;
    }
    setSubmitting(true);
    try {
        const user = Auth.signUp({
            username,
            password,
            attributes: {
                email
            }
        })
        user.then((data) => {
            
            if(data["userConfirmed"] === true){
                props.setUserSub(username);
                props.setAuthState('signin');
                props.setUserMessage({
                    "type": "success",
                    "content": "Thank you for signing up, you can now sign in below."
                })
            }
            else{
                setSubmitting(false);
                props.setUserMessage({
                    "type": "error",
                    "content": "That email is already in use, if you'd like to sign in click here"
                })
            } //setSignUpConfirmed(true);
        })
        .catch((error) => {
            setSubmitting(false);
            if(error.message === "User already exists"){
                error.message = "the username is already taken, please choose another one."
            }
            props.setUserMessage({
                "type": "error",
                "content": `Oops looks like there's an issue: ${error.message}`
            })
        }
        );
    } catch (error) {
        setSubmitting(false);
    }
}


  return (
      <>
    <TopMenuBar setLoggedLanguage={props.setDefinedLanguage} language={props.language} setAuthState={props.setAuthState} setUserMessage={props.setUserMessage}/>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {props.userMessage && props.userMessage.type && <Alert severity={props.userMessage.type}>{props.userMessage.content}</Alert>}
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                onChange={(event) => setUsername(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="confirmEmail"
                label="Confirm Email Address"
                name="confirmEmail"
                autoComplete="confirmEmail"
                onChange={(event) => setEmailConfirm(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="confirm-password"
                onChange={(event) => setPasswordConfirm(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
            { !submitting ? <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={sendSignUp}>
            Sign Up
          </Button>: <CircularProgress />}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Container>
    </>
  );
}

export default SignUp