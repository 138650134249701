import {useEffect, useState} from 'react';
import {Container, Button, Typography, CircularProgress} from '@material-ui/core';
import LanguageOptions from '../Grammar/Options';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { StylesDictionary } from '../../TypeScriptInterfaces';
import {default as currentUseState} from 'react-usestateref';
import axios from 'axios';
import { WordCard, ValidationTextField, DarkerDisabledTextField } from '.';
import { InitiateSpeaking } from '../ReadingListeningSpeaking';
//import { conjugatorURL } from '../../Config';
import * as RECOIL from '../../Recoil';
import { useRecoilValue } from 'recoil';
import { speechrecognitionURL, texttospeechURL } from '../../Config';
import { queries } from '../../graphql';
import GraphQLAPI, { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import https, { Agent } from 'https';

const styles:StylesDictionary = {
    correctAnswerButton: {
        backgroundColor: 'green'
    },
    incorrectAnswerButton: {
        backgroundColor: 'red'
    }
}


interface TypingInputProps {
    conjugated?: Object
    setScore: Function
    score: number
    question: any
    answer?: any
    words: Array<WordObject>
    questionType: string
    language: string
    allowAdvance: boolean
    setAllowAdvance: Function
    isLoggedIn: boolean
    filteredWords?: any
}

// list = ["D", "F", "Q"]

interface WordObject {
    word: string
    translation?: string
    partOfSpeech?: string
}

interface CardSelectInputProps {
    conjugated?: Object
    setScore: Function
    score: number
    question?: any
    answer?: any
    words: Array<string>
    questionType: string
    language: string
    allowAdvance: boolean
    setAllowAdvance: Function
    isLoggedIn: boolean
    filteredWords?: any
}

interface ButtonStates {
    tense: boolean
    person: boolean
    number: boolean
    gender: boolean
    type: boolean
    mood?: boolean
}

interface CongjugatedProps {
    word: any
    translation?: string
    partOfSpeech?: string
    language: string
}


export const TypingInput = (props:TypingInputProps) => {
    const selectedLanguage = useRecoilValue(RECOIL.selectedLanguage)

    //const url: string = `http://${conjugatorURL}:3001/conjugate/${selectedLanguage}`;
    const [conjugated, setConjugated] = useState<any>(props.conjugated);
    const [userAnswer, setUserAnswer, userAnswerRef] = currentUseState({"tense": "", "person": "", "number": "", "gender": "", "type": ""})
    const [revealed, setRevealsed] = useState(false);
    const [buttonStates, setButtonStates] = useState<ButtonStates>({
        "tense": false,
        "person": false,
        "number": false,
        "gender": false,
        "type": false
    })
    const [showWordCard, setShowWordCard] = useState(false);
    const [loadedWord, setLoadedWord] = useState<any>(props.words[Math.floor(Math.random() * props.words.length)])
    const [allowAdvance, setAllowAdvance] = useState(false);

    const loadAndDisplayWord = () => {
        const newWord = props.words[Math.floor(Math.random() * props.words.length)]
        if(props.questionType === 'conjugation'){
            generateConjugated({word: newWord, language: props.language})
            setLoadedWord(newWord)
            setUserAnswer({"tense": "", "person": "", "number": "", "gender": "", "type": ""})
            setButtonStates({
                "tense": false,
                "person": false,
                "number": false,
                "gender": false,
                "type": false
            })
            setAllowAdvance(false);
            setRevealsed(false);
            setShowWordCard(false)
        }
      }

      const generateConjugated = async (props:CongjugatedProps) => {
        try {
            const buff = Buffer.from(props.word, 'utf-8');
            const base64 = buff.toString('base64');

            const response:any = await GraphQLAPI.graphql({
                query: queries.getConjugatedWord,
                variables: {'language': props.language.toLowerCase(), 'base64EncodedString': base64},
                authMode: GRAPHQL_AUTH_MODE.API_KEY
              })

              console.log("ASDASDAS", response)
            //const response = await axios.get(url, { params: { word: base64, language: props.language.toLowerCase() } });
            //const updated = response.data.data.conjugatedWord.settings.tense
            if((props.language.toLowerCase() === 'arabic' && response.data.data.conjugatedWord.settings.tense === 'present') || (props.language.toLowerCase() === 'arabic' && response.data.data.conjugatedWord.settings.tense === 'future')){
                response.data.data.conjugatedWord.settings.tense = 'present/future'
            }
            setConjugated(response.data.data.conjugatedWord)
        } catch (exception) {
        }
    }


    const handleInput = (event:any) => {
        if(event.target.id === 'tense'){
            setUserAnswer({...userAnswer, tense: event.target.value})
            if(event.target.value === conjugated.settings.tense){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, tense: true})
        }
        if(event.target.id === 'person'){
            setUserAnswer({...userAnswer, person: event.target.value})
            if(event.target.value === conjugated.settings.person){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, person: true})
        }
        if(event.target.id === 'number'){
            setUserAnswer({...userAnswer, number: event.target.value})
            if(event.target.value === conjugated.settings.number){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, number: true})
        }
        if(event.target.id === 'gender'){
            setUserAnswer({...userAnswer, gender: event.target.value})
            if(event.target.value === conjugated.settings.gender){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, gender: true})
        }
        if(userAnswer.tense === conjugated.settings.tense && userAnswer.person === conjugated.settings.person && userAnswer.number === conjugated.settings.number && userAnswer.gender === conjugated.settings.gender){
            props.setScore(props.score+20)
            loadAndDisplayWord()
        }
        if(userAnswerRef.current.tense && userAnswerRef.current.person && userAnswerRef.current.number && userAnswerRef.current.gender){
            setAllowAdvance(true)
        }
    }

    const revealAnswer = () => {
        setAllowAdvance(true);
        setRevealsed(true);
        setShowWordCard(true)
        setButtonStates({
            "tense": true,
            "person": true,
            "number": true,
            "gender": true,
            "type": true
        })
      }

    return(
        <Container>
        {userAnswer.tense === conjugated.settings.tense || revealed ? <> <ValidationTextField InputLabelProps={{ shrink: true}} id="tense" onChange={handleInput} value={conjugated.settings.tense}/> </> : <> <DarkerDisabledTextField id="tense" label="tense" InputLabelProps={{ shrink: true}}  onChange={handleInput}/> </>}
        {userAnswer.person === conjugated.settings.person || revealed ? <> <ValidationTextField InputLabelProps={{ shrink: true}}  id="tense" onChange={handleInput} value={conjugated.settings.person}/> </> : <> <DarkerDisabledTextField id="person" label="person" InputLabelProps={{ shrink: true}}  onChange={handleInput}/> </>}
        {userAnswer.number === conjugated.settings.number || revealed ? <> <ValidationTextField InputLabelProps={{ shrink: true}}  id="tense" onChange={handleInput} value={conjugated.settings.number}/> </> : <> <DarkerDisabledTextField id="number" label="number" InputLabelProps={{ shrink: true}}  onChange={handleInput}/> </>}
        {userAnswer.gender === conjugated.settings.gender || revealed ? <> <ValidationTextField InputLabelProps={{ shrink: true}}  id="tense" onChange={handleInput} value={conjugated.settings.gender}/> </> : <> <DarkerDisabledTextField id="gender" label="gender" InputLabelProps={{ shrink: true}}  onChange={handleInput}/> </>}
        </Container>
        )
}

export const CardSelectInput = (props:CardSelectInputProps) => {
    
    const selectedLanguage = useRecoilValue(RECOIL.selectedLanguage).toLowerCase()
    const [audioData, setAudioData] = useState('');

    const generateConjugated = (conjugatedProps:CongjugatedProps) => {
        try {
                const buff = Buffer.from(conjugatedProps.word, 'utf-8');
                const base64 = buff.toString('base64');
                const response:any = GraphQLAPI.graphql({
                    query: queries.getConjugatedWord,
                    variables: {'language': props.language.toLowerCase(), 'base64EncodedString': base64},
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                  })
                  response.then((data:any) => {
                        //console.log("ASDASDAS", data.data.getConjugatedWord)
                        if((props.language.toLowerCase() === 'arabic' && data.data.getConjugatedWord.settings.tense === 'present') || (props.language.toLowerCase() === 'arabic' && data.data.getConjugatedWord.settings.tense === 'future')){
                            data.data.getConjugatedWord.settings.tense = 'present/future'
                        }
                        if(data.data.getConjugatedWord.settings === undefined && props.language.toLowerCase() === 'russian'){
                            loadAndDisplayWord()
                            return
                        }   

                        setConjugated(data.data.getConjugatedWord)
                        const buff = Buffer.from(data.data.getConjugatedWord.conjugatedWord, 'utf-8' )
                        const base64ForAudio = buff.toString('base64');
                        fetch(`https://tts-service.default.svc.cluster.local/tts/${props.language.toLowerCase()}`, {
                            method: 'POST', 
                            body: JSON.stringify({input: base64ForAudio, language: props.language.toLowerCase()})
                        })
                        .then((data:any) => {
                            console.log("TTS DATA", data)
                        })
                        .catch((error:any) => {
                            console.log("ERROR", error)
                        })
                        // const ttsResponse:any = GraphQLAPI.graphql({
                        //     query: queries.getTts,
                        //     variables: {'language': props.language.toLowerCase(), 'input': base64ForAudio},
                        //     authMode: GRAPHQL_AUTH_MODE.API_KEY
                        //   })
                        //   ttsResponse.then((data:string) => {
                        //     console.log("AUDIO", data)
                        //     //setAudioData("data:audio/wav;base64,"  + data.data.encoded_audio)
                        //   })
                        // const buff = Buffer.from(data.data.conjugatedWord, 'utf-8' )//conjugated.conjugatedWord, 'utf-8');
                        // const base64 = buff.toString('base64');
                        // axios.get(`http://${texttospeechURL}:3003/tts/${selectedLanguage}`, { params: { input: base64 } })
                        //     .then((data) => {
                        //         //const languageAudioResponse = new Audio("data:audio/wav;base64," + data.data.encoded_audio)
                        //         setAudioData("data:audio/wav;base64,"  + data.data.encoded_audio)
                        //         }
                        //     )
                  })

                // const response:any = axios.get(url, { params: { word: base64, language: props.language.toLowerCase() } })
                // .then((data) => {
                //     if((props.language.toLowerCase() === 'arabic' && data.data.settings.tense === 'present') || (props.language.toLowerCase() === 'arabic' && data.data.settings.tense === 'future')){
                //         data.data.settings.tense = 'present/future'
                //     }
                //     if(data.data.settings === undefined && props.language.toLowerCase() === 'russian'){
                //         loadAndDisplayWord()
                //         return
                //     }   
                //     console.log(data.data)
                //     setConjugated(data.data)

                //     const buff = Buffer.from(data.data.conjugatedWord, 'utf-8' )//conjugated.conjugatedWord, 'utf-8');
                //     const base64 = buff.toString('base64');
                //     axios.get(`http://${texttospeechURL}:3003/tts/${selectedLanguage}`, { params: { input: base64 } })
                //         .then((data) => {
                //             //const languageAudioResponse = new Audio("data:audio/wav;base64," + data.data.encoded_audio)
                //             setAudioData("data:audio/wav;base64,"  + data.data.encoded_audio)
                //             }
                //         )

                // })
                .catch((error:any) => {
                })
        } catch (exception) {
        }
    }
    //const url: string = `http://${conjugatorURL}:3001/conjugate/${selectedLanguage}`;
    const [conjugated, setConjugated] = useState<any>('');
    const [userAnswer, setUserAnswer, userAnswerRef] = currentUseState({"tense": "", "person": "", "number": "", "gender": "", "type": "", "mood": ""})
    const [revealed, setRevealsed] = useState(false);
    const [wordToTranslation, setWordToTranslation] = useState(true);
    const [buttonStates, setButtonStates] = useState<ButtonStates>({
        "tense": false,
        "person": false,
        "number": false,
        "gender": false,
        "type": false,
        "mood": false
    })
    const [showWordCard, setShowWordCard] = useState(false);
    const [vocabAnswer, setVocabAnswer, vocabAnswerRef] = currentUseState('');
    const [loadedWord, setLoadedWord, loadedWordRef] = currentUseState(props.words[Math.floor(Math.random() * props.words.length)])
    const [allowAdvance, setAllowAdvance] = useState(false);
    //const [filteredWords, setFilteredWords, filteredWordsRef] = currentUseState(getRandom(props.words, 5)?.filter(Boolean))
    const [translations, setTranslations, translationsRef] = currentUseState([""])
    const [foreignText, setForeignText, foreignTextRef] = currentUseState([""])
    const [playAudio, setPlayAudio, playAudioRef] = currentUseState(false);
    const [playAudioCon, setPlayAudioCon, playAudioRefCon] = currentUseState(false);


    function shuffleArray(array:Array<any>) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }

    const handleTranslation = () => {
        if(props.words.length > 1){
            const randomArrayofWords:Array<any> = getRandom(props.words, 5)!
            const arrayIncludingAnswer = shuffleArray(randomArrayofWords.concat(loadedWordRef.current))
            setTranslations(arrayIncludingAnswer.map((element:any) => element.translation))
            setForeignText(arrayIncludingAnswer.map((element:any) => element.word))
        }
    }

    useEffect(() => {
        setLoadedWord(props.words[Math.floor(Math.random() * props.words.length)])
        if(props.questionType === 'conjugation'){
            generateConjugated(loadedWordRef.current)
        }
        if(props.questionType === 'vocabulary'){
            handleTranslation()
        }
        setVocabAnswer('')
        return () => {
        }
      }, [props.words]);

      //const selectedLanguage = useRecoilValue(RECOIL.selectedLanguage).toLowerCase();
    //const [audioData, setAudioData] = useState('');


    const loadAndDisplayWord = () => {
        const newWord:any = props.words[Math.floor(Math.random() * props.words.length)]
        setPlayAudio(false);
        if(props.questionType === 'conjugation'){
            generateConjugated({word: newWord.word, language: props.language})
            setLoadedWord(newWord)
            setUserAnswer({"tense": "", "person": "", "number": "", "gender": "", "type": "", "mood": ""})
            setButtonStates({
                "tense": false,
                "person": false,
                "number": false,
                "gender": false,
                "type": false,
                "mood": false
            })
            setAllowAdvance(false);
            setRevealsed(false);
            setShowWordCard(false)
        }
        if(props.questionType === 'vocabulary'){
            setLoadedWord(newWord)
            setVocabAnswer("");
            handleTranslation();
            setAllowAdvance(false);
            setShowWordCard(false);
            setRevealsed(false);
        }
      }

    const handleInput = (event:any) => {
        if(event.target.id === 'tense'){
            setUserAnswer({...userAnswer, tense: event.target.value})
            if(event.target.value === conjugated.settings.tense){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, tense: true})
        }
        if(event.target.id === 'person'){
            setUserAnswer({...userAnswer, person: event.target.value})
            if(event.target.value === conjugated.settings.person){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, person: true})
        }
        if(event.target.id === 'number'){
            setUserAnswer({...userAnswer, number: event.target.value})
            if(event.target.value === conjugated.settings.number){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, number: true})
        }
        if(event.target.id === 'gender'){
            setUserAnswer({...userAnswer, gender: event.target.value})
            if(event.target.value === conjugated.settings.gender){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, gender: true})
        }
        if(event.target.id === 'type'){
            setUserAnswer({...userAnswer, type: event.target.value})
            if(event.target.value === conjugated.settings.type){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, type: true})
        }
        if(event.target.id === 'mood'){
            setUserAnswer({...userAnswer, mood: event.target.value})
            if(event.target.value === conjugated.settings.mood){
                props.setScore(props.score+5)
            }
            setButtonStates({...buttonStates, mood: true})
        }
        if(conjugated.settings && userAnswer.tense === conjugated.settings.tense && userAnswer.person === conjugated.settings.person && userAnswer.number === conjugated.settings.number && userAnswer.gender === conjugated.settings.gender){
            props.setScore(props.score+20)
            loadAndDisplayWord()
        }
        if(userAnswerRef.current.tense && userAnswerRef.current.person && userAnswerRef.current.number && userAnswerRef.current.gender){
            setAllowAdvance(true)
        }
        if(vocabAnswerRef.current){

        }
        if(event.target.id === 'vocabulary'){
            setVocabAnswer(event.target.value)
            if(vocabAnswerRef.current.valueOf() === loadedWordRef.current.translation.valueOf()){
                setAllowAdvance(true)
                props.setScore(props.score+20)
            }
        }
    }

    const revealAnswer = () => {
        setAllowAdvance(true);
        setRevealsed(true);
        setShowWordCard(true);
        setPlayAudio(true);
        setVocabAnswer(loadedWordRef.current.word.valueOf())
        setButtonStates({
            "tense": true,
            "person": true,
            "number": true,
            "gender": true,
            "type": true,
            "mood": true
        })
      }

      const Conjugation = (
        (conjugated.settings) ?
        <Container>
            <>
            <Typography>
                Parse {conjugated.conjugatedWord}
            </Typography>
            <InitiateSpeaking audio={audioData} playAudio={setPlayAudioCon}/>
            </>
        {conjugated.settings.tense 
        ?
        <Container>
            <Typography> Select the tense:</Typography>
            {
                LanguageOptions[props.language.toLowerCase()].Verbs.Tense.map((element:string, index:number) => {
                    return(
                        <Button key={index} style={((conjugated.settings.tense === element && userAnswer.tense) || (revealed && conjugated.settings.tense === element)) ? styles.correctAnswerButton : undefined || (userAnswer.tense && userAnswer.tense === element ? styles.incorrectAnswerButton : undefined)} id="tense" value={element} disabled={buttonStates.tense} onClick={handleInput}> {element}</Button>
                    )
                })
            }
        
        {userAnswer.tense && userAnswer.tense === conjugated.settings.tense ? 
        <CheckCircleIcon/>
        :
        null
        }
        {userAnswer.tense && userAnswer.tense !== conjugated.settings.tense ? 
        <CancelIcon/>
        :
        null
        }
        </Container>
        :
        null
        }
        {conjugated.settings.type 
        ?
        <Container>
            <Typography> Select the type:</Typography>
            {
                LanguageOptions[props.language.toLowerCase()].Verbs.Type.map((element:string, index:number) => {
                    return(
                        <Button key={index} style={((conjugated.settings.type === element && userAnswer.type) || (revealed && conjugated.settings.type === element)) ? styles.correctAnswerButton : undefined || (userAnswer.type && userAnswer.type === element ? styles.incorrectAnswerButton : undefined)} id="type" value={element} disabled={buttonStates.type} onClick={handleInput}> {element}</Button>
                    )
                })
            }
        
        {userAnswer.type && userAnswer.type === conjugated.settings.type ? 
        <CheckCircleIcon/>
        :
        null
        }
        {userAnswer.type && userAnswer.type !== conjugated.settings.type ? 
        <CancelIcon/>
        :
        null
        }
        </Container>
        :
        null
        }
                {conjugated.settings.mood 
        ?
        <Container>
            <Typography> Select the mood:</Typography>
            {
                LanguageOptions[props.language.toLowerCase()].Verbs.Mood.map((element:string, index:number) => {
                    return(
                        <Button key={index} style={((conjugated.settings.mood === element && userAnswer.mood) || (revealed && conjugated.settings.mood === element)) ? styles.correctAnswerButton : undefined || (userAnswer.mood && userAnswer.mood === element ? styles.incorrectAnswerButton : undefined)} id="mood" value={element} disabled={buttonStates.mood} onClick={handleInput}> {element}</Button>
                    )
                })
            }
        
        {userAnswer.mood && userAnswer.mood === conjugated.settings.mood ? 
        <CheckCircleIcon/>
        :
        null
        }
        {userAnswer.mood && userAnswer.mood !== conjugated.settings.mood ? 
        <CancelIcon/>
        :
        null
        }
        </Container>
        :
        null
        }
            {conjugated.settings.person 
        ?
        <Container>
            <Typography> Select the person:</Typography>
            {
                LanguageOptions[props.language.toLowerCase()].Verbs.Person.map((element:string, index:number) => {
                    return(
                        <Button key={index} style={((conjugated.settings.person === element && userAnswer.person) || (revealed && conjugated.settings.person === element))  ? styles.correctAnswerButton : undefined || (userAnswer.person && userAnswer.person === element ? styles.incorrectAnswerButton : undefined)} id="person" value={element} disabled={buttonStates.person} onClick={handleInput}> {element}</Button>
                        )
                    })
                }
            
            {userAnswer.person && userAnswer.person === conjugated.settings.person ? 
            <CheckCircleIcon/>
            :
            null
            }
            {userAnswer.person && userAnswer.person !== conjugated.settings.person ? 
            <CancelIcon/>
            :
            null
            }
            </Container>
            :
            null
            }
            {conjugated.settings.number 
        ?
        <Container>
            <Typography> Select the number:</Typography>
            {
                LanguageOptions[props.language.toLowerCase()].Verbs.Number.map((element:string, index:number) => {
                    return(
                        <Button key={index} style={((conjugated.settings.number === element && userAnswer.number) || (revealed && conjugated.settings.number === element)) ? styles.correctAnswerButton : undefined || (userAnswer.number && userAnswer.number === element ? styles.incorrectAnswerButton : undefined)} id="number" value={element} disabled={buttonStates.number} onClick={handleInput}> {element}</Button>
                        )
                    })
                }
            
            {userAnswer.number && userAnswer.number === conjugated.settings.number ? 
            <CheckCircleIcon/>
            :
            null
            }
            {userAnswer.number && userAnswer.number !== conjugated.settings.number ? 
            <CancelIcon/>
            :
            null
            }
            </Container>
            :
            null
            }
            {conjugated.settings.gender
        ?
        <Container>
            <Typography> Select the gender:</Typography>
            {
                LanguageOptions[props.language.toLowerCase()].Verbs.Gender.map((element:string, index:number) => {
                    return(
                        <Button key={index} style={((conjugated.settings.gender === element && userAnswer.gender) || (revealed && conjugated.settings.gender === element)) ? styles.correctAnswerButton : undefined || (userAnswer.gender && userAnswer.gender === element ? styles.incorrectAnswerButton : undefined)} id="gender" value={element} disabled={buttonStates.gender} onClick={handleInput}> {element}</Button>
                        )
                    })
                }
            
            {userAnswer.gender && userAnswer.gender === conjugated.settings.gender ? 
            <CheckCircleIcon/>
            :
            null
            }
            {userAnswer.gender && userAnswer.gender !== conjugated.settings.gender ? 
            <CancelIcon/>
            :
            null
            }
            </Container>
            :
            null
        }
        <Container>
          <Button
          disabled={allowAdvance}
          onClick={revealAnswer}
          variant="contained">
            reveal answer
          </Button>

          { <Button
            disabled={!allowAdvance}
            onClick={loadAndDisplayWord}
            variant="contained"
            >
              next
            </Button>}
      </Container>
    </Container>
    :
    <>
    </>
      )

      function getRandom(arr:Array<any>, n:number) {
          try{
            var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
          }
          catch(error){
          }
    }

    const Vocabulary = (
        <>
        {wordToTranslation ? (
        <>
        {loadedWord ? 
        <>
        <Typography>
            What is the translation of: {loadedWordRef.current.word}
        </Typography>
        </>
        :
        <>
        </>
        }
        {translations && loadedWordRef.current && loadedWordRef.current.word ? <>
                    {
                translations.map((element:any, index:number) => {
                    return(
                        <Button disabled={(vocabAnswerRef.current) || (revealed) ? true : false} value={element} key={index} onClick={handleInput} style={((vocabAnswerRef.current.valueOf() === loadedWordRef.current.translation.valueOf() && vocabAnswerRef.current.valueOf() === element.valueOf()) ? styles.correctAnswerButton : undefined) || ((vocabAnswerRef.current.valueOf() && vocabAnswerRef.current.valueOf() !== loadedWordRef.current.translation.valueOf() && vocabAnswerRef.current.valueOf() == element) ? styles.incorrectAnswerButton : undefined)} id="vocabulary">
                            {element}
                        </Button>
                        )
                    })
                }

        {vocabAnswerRef.current && vocabAnswerRef.current === loadedWordRef.current.translation ? 
            <CheckCircleIcon/>
            :
            null
            }
            {vocabAnswerRef.current && vocabAnswerRef.current !== loadedWordRef.current.translation ? 
            <CancelIcon/>
            :
            null
            }
    </> :
    null
    
    }
    { loadedWordRef.current ? <Container>
          <Button
          disabled={allowAdvance}
          onClick={revealAnswer}
          variant="contained">
            reveal answer
          </Button>

          { <Button
            disabled={!allowAdvance}
            onClick={loadAndDisplayWord}
            variant="contained"
            >
              next
            </Button>}
      </Container>
      :
      <>
      </>
      }
    </>
    )
    :
    (
        <>
        <Typography>
            What is the translation of: {loadedWordRef.current.translation}
        </Typography>
{foreignText ? <>
                    {
                foreignText.map((element:any, index:number) => {
                    return(
                        <Button value={element}>
                            {element}
                        </Button>
                        )
                    })
                }

        {vocabAnswer && vocabAnswer === loadedWordRef.current ? 
            <CheckCircleIcon/>
            :
            null
            }
            {vocabAnswer && vocabAnswer !== loadedWordRef.current ? 
            <CancelIcon/>
            :
            null
            }
    </> :
    null
    
    }
    </>
    )}
    </>
    )

    const FetchTester = () => {

        const testing = (event:any) => {
            const doc:any = document
            const value:any = doc.getElementById('fetcher')
            // const httpsAgent = new https.Agent({ rejectUnauthorized: false });
            // axios.post(value.value, {
            //     input: 'dGVzdGluZw==',
            //     language: 'arabic',
            //     httpsAgent: httpsAgent,
            //   })
            //   .then(function (response) {
            //     console.log(response);
            //   })
            //   .catch(function (error) {
            //     console.log(error);
            //   });
            console.log("THIS IS URL", value.value)

            fetch(value.value, {
                method: "GET"
            })
            .then((data:any) => {
                console.log("THEN", data)
            })
            .catch((error:any) => {
                console.log("ERROR", error)
            })
        }

        return(
         <>
            <input id="fetcher"  type="text" name="text"></input>
            <input onClick={testing} type="submit" />

         </>   
        )
    }

    return (props.questionType === "conjugation") ? (
        <>
        <>
        <FetchTester/>
        </>
        {Conjugation}
        {showWordCard 
          ?
      <Container>
            <WordCard audio={loadedWordRef.current.audio} word={loadedWordRef.current.word} translation={loadedWordRef.current.translation} partOfSpeech={loadedWordRef.current.partOfSpeech} isLoggedIn={props.isLoggedIn}/>
      </Container>
      :
      null
      }
        </>
    )
    :
    (
        <>
        {Vocabulary}
        {showWordCard 
          ?
      <Container>
            <WordCard audio={loadedWordRef.current.audio}  word={loadedWordRef.current.word} translation={loadedWordRef.current.translation} partOfSpeech={loadedWordRef.current.partOfSpeech} isLoggedIn={props.isLoggedIn}/>
      </Container>
      :
      null
      }
        </>
    )
    
}