/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAudio = /* GraphQL */ `
  query GetAudio($input: String, $language: String!) {
    getAudio(input: $input, language: $language)
  }
`;
export const getConjugatedWord = /* GraphQL */ `
  query GetConjugatedWord($base64EncodedString: String!, $language: String!) {
    getConjugatedWord(
      base64EncodedString: $base64EncodedString
      language: $language
    ) {
      conjugatedWord
      settings {
        gender
        number
        person
        tense
        type
      }
    }
  }
`;
export const getStripeCheckoutSession = /* GraphQL */ `
  query GetStripeCheckoutSession($sessionId: String) {
    getStripeCheckoutSession(sessionId: $sessionId)
  }
`;
export const getStripeSubscriptionOptions = /* GraphQL */ `
  query GetStripeSubscriptionOptions(
    $currency: String
    $product_ids: [String]
  ) {
    getStripeSubscriptionOptions(
      currency: $currency
      product_ids: $product_ids
    ) {
      billing_scheme
      currency
      id
      lookup_key
      metadata
      nickname
      object
      product
      recurring {
        aggregate_usage
        interval
        interval_count
        trial_period_days
        usage_type
      }
      tiers_mode
      transform_quantity
      type
      unit_amount
      unit_amount_decimal
    }
  }
`;
export const getTts = /* GraphQL */ `
  query GetTts($input: String, $language: String) {
    getTTS(input: $input, language: $language) {
      encoded_audio
    }
  }
`;
export const getUserDetails = /* GraphQL */ `
  query GetUserDetails {
    getUserDetails {
      email
      firstName
      lastName
      membership
      membershipStatus
      renewalDate
      startDate
      stripeId
      subscriptionID
      username
    }
  }
`;
export const getUserWordLists = /* GraphQL */ `
  query GetUserWordLists {
    getUserWordLists {
      language
      owner
      visibility
      words {
        foreignWord
        partOfSpeech
        translation
      }
    }
  }
`;
export const getWordBatch = /* GraphQL */ `
  query GetWordBatch($language: String!, $lower_range: Int, $upper_range: Int) {
    getWordBatch(
      language: $language
      lower_range: $lower_range
      upper_range: $upper_range
    ) {
      audio
      frequency
      word {
        foreignWord
        partOfSpeech
        translation
      }
    }
  }
`;
