import client from '../../APIController';
import gql from 'graphql-tag';
import {queries, mutations} from '../../graphql';
import {t} from '../Basic';

export const getStripeSubscriptionOptions = (currency:string, type:string) => {

    const response:any = client.query({
        query: gql(queries.getStripeSubscriptionOptions),
        variables: {'currency': currency.toLowerCase(), 'type': type}})

    return response
}

export const putStripeSessionId = () => {

}

export const createStripeCheckoutSession = (priceID:string) => {
    const customerDetails:any = client.query({
        query: gql(queries.getUserDetails),
        fetchPolicy: "network-only"
    })
    .then((data) => {
    })

    return customerDetails
}

export const getStripeCheckoutSession = () => {

}

export const cancelStripeSubscription = () => {
    const cancelSubscription:any = client.mutate({
        mutation: gql(mutations.cancelStripeSubscription),
        fetchPolicy: "no-cache"
    })
}