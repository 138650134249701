import {Container} from '@material-ui/core';
import {WordLoader} from '../Basic';
import {useState} from 'react';
import { TypingInput, CardSelectInput } from '../Basic/TestingScreens';
import { Scoring } from '../Basic';

const Vocabulary = (props:any) => {

    const [loadedWords, setLoadedWords] = useState([]);
    const [allowAdvance, setAllowAdvance] = useState(false);
    const [inputType, setInputType] = useState('');
    const [score, setScore] = useState(0)

    return(
        <>
        <WordLoader loadedWords={loadedWords} setLoadedWords={setLoadedWords} language={props.language}/>
        <Container>
            {inputType === "typing" 
            ?
            <TypingInput isLoggedIn={props.isLoggedIn} allowAdvance={allowAdvance} setAllowAdvance={setAllowAdvance} score={score} setScore={setScore} questionType="vocabulary" question="What is the translation of" words={loadedWords} language={props.language}/>
            :
            <CardSelectInput isLoggedIn={props.isLoggedIn} allowAdvance={allowAdvance} setAllowAdvance={setAllowAdvance} score={score} setScore={setScore} questionType="vocabulary" question="What is the translation of" words={loadedWords} language={props.language}/>
            }
            {loadedWords.length > 10 ?
                <Scoring score={score}/>
                :
                <>
                </>
            }
        </Container>
        </>
    )
}

export default Vocabulary;