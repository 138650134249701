import React, { Component, createRef } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import {customKeyboardTemplate, convertTemplateToRSX} from './Library';
import { Select, MenuItem } from '@material-ui/core';
const keyboardTemplate:any = customKeyboardTemplate;

type Props = {
    layout: any,
    isCustom: boolean,
    letterArray: Array<string>
}

type State = { 
    layout: any,
    layoutName: string,
    showSelector: boolean,
    selectedLetter: string,
    customTemplate: any
};


class KeyboardWrapper extends Component<Props, State> {

    constructor(props:any) {
        super(props);
        this.state = {
            layout: props.layout,
            layoutName: 'default',
            showSelector: false,
            selectedLetter: '',
            customTemplate: ''
        };
        this.convertInput = this.convertInput.bind(this)
        this.handleShift = this.handleShift.bind(this);
        this.saveCustomSelection = this.saveCustomSelection.bind(this);
      }
  
    componentDidMount() {
      const self = this;
      document.addEventListener('keypress', function (event) {
        const keyPressed = event.code
        self.convertInput(keyPressed);
      });
    }
  
    componentWillUnmount() {
      document.removeEventListener('keypress', function (event) {
      })
    }

  onKeyPress = (button:any) => {
    //var x = document.getElementById("myBtn").value;
    if(this.props.isCustom){
      this.setState({showSelector: true})
    }
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  }

  convertInput = (input:string) => {
    if(this.props.isCustom){
      this.setState({selectedLetter: input})
    }
  }

  handleShift = () => {

    let shiftToggle = this.state.layoutName === "default" ? "shift" : "default";

    this.setState({
        layoutName: shiftToggle
    })

  }

  saveCustomSelection = (event:any) => {
    const selectedValue = event.target.innerText
    const fromKey = this.state.selectedLetter

  }

  render(){


 const CustomButtonSelecto= (button:any) => {
   let letterArray = this.props.letterArray.map(s => { return s.trim()})
  return(
    <Select
    >
      {letterArray.map((letter, index) => {
       return (
        <MenuItem key={index} value={letter} onClick={this.saveCustomSelection}>
          {letter}
        </MenuItem>
      )
      })}
    </Select>
  )
 }

    return (
        <div className="keyboard">
          <Keyboard
            onKeyPress={this.onKeyPress}
            handleKeyDown={this.onKeyPress}
            layout={this.props.layout.layout}
            layoutName={this.state.layoutName}
          />
          {(this.state.showSelector) ? <CustomButtonSelecto/> : null}
        </div>
    );
  }
}

export default KeyboardWrapper;