import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsconfig from './aws-exports';
import {Auth} from 'aws-amplify';

const client = new AWSAppSyncClient({
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        try {
          return (await Auth.currentSession()).getIdToken().getJwtToken()
        } catch (e) {
          console.error(e);
          return ""; // In case you don't get the token, hopefully that is a public api and that should work with the API Key alone.
        }
      }
    }
  });


export default client;