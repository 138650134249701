import {keyboardMapDicts} from "./Library";

const keyboardLibrary:any = keyboardMapDicts

export const keyboardInputConverter = (input:any, userKeyboardLanguage:any, selectedInputLanguage:any, keyboardType:string, shift:boolean) => {
    const capitalisedCountry = selectedInputLanguage.toLowerCase().split(' ').map((word:any) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    const keyboardConverterName:any = userKeyboardLanguage.toLowerCase() + 'To' + capitalisedCountry + keyboardType.trim();
    let convertedValue:string
    if(shift === true){
        convertedValue = keyboardLibrary[keyboardConverterName]["Shift+"+input]
    }
    else{
      convertedValue = keyboardLibrary[keyboardConverterName][input];
    }
    return convertedValue
  }

  export const keyboardInputReverser = (input:any, userKeyboardLanguage:any, selectedInputLanguage:any, keyboardType:string, wordOrLetter:boolean) => {

    const capitalisedCountry = selectedInputLanguage.toLowerCase().split(' ').map((word:any) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    const keyboardConverterName:any = userKeyboardLanguage.toLowerCase() + 'To' + capitalisedCountry + keyboardType.trim();
    let convertedValue;
    const reversedMappings = swap(keyboardLibrary[keyboardConverterName])

    function swap(json:any){
      var ret:any = {};
      for(var key in json){
        ret[json[key]] = key;
      }
      return ret;
    }

    if(wordOrLetter === true){
      convertedValue = reversedMappings[input];
    }
    else{
      const wordCharArray = [...input]
      
      convertedValue = wordCharArray.map(function(char:string) { 
        char = reversedMappings[char];
        if(char !== undefined && char.length === 4){
          char = char[3].toLowerCase()
        }
        return char;
      })
      convertedValue = convertedValue.join(",")
    }
    return convertedValue
  }