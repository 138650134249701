/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://2xpd5igpmnbp3i3o27bnnrzhue.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ododwi25ifamtkd3kxukt7auge",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_X7oEjFcGr",
    "aws_user_pools_web_client_id": "4tl1s8hsastcramstmqbqtr1ko"
};


export default awsmobile;
