import { withStyles} from '@material-ui/styles';
import {TextField, Paper, InputBase} from '@material-ui/core';
import {t} from '../Basic';

export const DarkerDisabledTextField = withStyles({
    root: {
      "& .Mui-disabled": {
          "-webkit-text-fill-color": "black !important",
          textAlign: "right",
      },
      "& .MuiInputLabel-root": {
          color: "black"
      }
    }
  })(TextField);

export const EditAndSaveInputField = (props:any) => {
  
    return (
      <Paper component="form">
        {props.icon}
        <InputBase
        //   inputProps={{ 'label': 'search google maps', shrink: true, }}
          disabled={props.disabled}
          value={props.value}
          style={{color: 'black'}}
        />
      </Paper>
    );
  }

export const ValidationTextField = withStyles({
    root: {
      '& input:valid + fieldset': {
        borderColor: 'green',
        borderWidth: 5,
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 2,
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 9,
        borderColor: 'green',
        padding: '4px !important', // override inline-style
      },
      '& input:valid:hover + fieldset': {
        borderLeftWidth: 9,
        borderColor: 'green',
        padding: '4px !important', // override inline-style
      },
      "& .Mui-disabled": {
        "-webkit-text-fill-color": "black !important",
        textAlign: "right",
    },
    "& .MuiInputLabel-root": {
        color: "black"
    }
    },
  })(TextField);
