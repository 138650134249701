import { SignIn, SignUp, ResetPassword, EmailVerification} from '../Components/Authentication';

const AuthWrapper = (props:any) => {
    
        switch (props.authState){
            case "signin":
                return(
                    <SignIn isLoggedIn={props.isLoggedIn} language={props.language} setDefinedLanguage={props.setDefinedLanguage} userSub={props.userSub} setUserSub={props.setUserSub} setAuthState={props.setAuthState} userMessage={props.userMessage} setUserMessage={props.setUserMessage} setIsLoggedIn={props.setIsLoggedIn}/>
                );
            case "signup":
                return(
                    <SignUp isLoggedIn={props.isLoggedIn} language={props.language} setDefinedLanguage={props.setDefinedLanguage} userSub={props.userSub} setUserSub={props.setUserSub} setAuthState={props.setAuthState} userMessage={props.userMessage} setUserMessage={props.setUserMessage} setIsLoggedIn={props.setIsLoggedIn}/>
                );
            case "resetpassword":
                return(
                    <ResetPassword isLoggedIn={props.isLoggedIn} language={props.language} setDefinedLanguage={props.setDefinedLanguage} userSub={props.userSub} setUserSub={props.setUserSub} setAuthState={props.setAuthState} userMessage={props.userMessage} setUserMessage={props.setUserMessage}/>
                );
            case "TwoFactorSetupConfirmation":
                return(
                    <>
                    </>
                );
            case "logintwofactor":
                return(
                    <>
                    </>
                );
            case "verifyemail":
                return(
                    <EmailVerification isLoggedIn={props.isLoggedIn} language={props.language} setDefinedLanguage={props.setDefinedLanguage} userSub={props.userSub} setUserSub={props.setUserSub} authState={props.authState} setAuthState={props.setAuthState} userMessage={props.userMessage} setUserMessage={props.setUserMessage} />
                );
            default:
                return(
                    <SignIn isLoggedIn={props.isLoggedIn} language={props.language} setDefinedLanguage={props.setDefinedLanguage} userSub={props.userSub} setUserSub={props.setUserSub} setAuthState={props.setAuthState} userMessage={props.userMessage} setUserMessage={props.setUserMessage} setIsLoggedIn={props.setIsLoggedIn}/>
                );
        }
    }


export default AuthWrapper;