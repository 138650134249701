import TopMenuBar from '../Components/Basic/Header';
import React, {useState, useEffect} from 'react';
import { Theme } from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Typography, Container, IconButton, Button} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Membership from '../Components/Membership/Membership';
import { EditAndSaveInputField } from '../Components/Basic';
import PersonIcon from '@material-ui/icons/Person';
import {queries} from '../graphql';
import client from '../APIController';
import gql from 'graphql-tag';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import {t} from '../Components/Basic';
import Analytics from '@aws-amplify/analytics';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{width: '100%'}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{height: '100%'}}>
          <Typography style={{height: '100%'}}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    display: 'flex',
    height: '100%'
  },
  tabs: {
    borderRight: `1px solid grey`,
    height: '100%'
  },
}));

const Profile = (props:any) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [editEnabled, setEditEnabled] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const editField = (event:any) => {
    setEditEnabled(true)
  }

  const saveField = (event:any) => {
      setEditEnabled(false)
  }

    const fetchUserDetails = () => {
    
        const response:any = client.query({
          query: gql(queries.getUserDetails)
        })
        response.then((data:any) => {
            setUserDetails(data["data"]["getUserDetails"])
        })
    }

    const fetchUserDetailsObject:any = userDetails
    const {email, firstName, lastName, username} = fetchUserDetailsObject;

  useEffect(() => {
      fetchUserDetails();
    }, []);

    const PinPointTest = () => {
      //Record an event
      Analytics.record('some-event-name');
    
    //Record a custom event
      Analytics.record({
        name: 'Album',
        attributes: { genre: 'Rock', year: '1989' }
      });
    }

  return (
    <div>
     <TopMenuBar setLoggedLanguage={props.setDefinedLanguage} language={props.language} isLoggedIn={props.isLoggedIn} setIsLoggedIn={props.setIsLoggedIn} setAuthState={props.setAuthState} setUserMessage={props.setUserMessage}/>
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Details" {...a11yProps(0)} />
        <Tab label="Custom Keyboards" {...a11yProps(1)} />
        <Tab label="Custom Wordlists" {...a11yProps(2)} />
        <Tab label="Membership" {...a11yProps(3)} />
        <Tab label="Other" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0}>
          <Container>
          <EditAndSaveInputField
            disabled={!editEnabled}
            variant="outlined"
            margin="normal"
            id="username"
            value={username}
            label="Username"
            name="username"
            icon={<PersonIcon/>}
            // onChange={(event) => setUsername(event.target.value)}
            autoComplete="username"
            autoFocus
          />
            <EditAndSaveInputField
            disabled={!editEnabled}
            variant="outlined"
            margin="normal"
            id="email"
            label="email"
            name="email"
            value={email}
            // onChange={(event) => setUsername(event.target.value)}
            autoComplete="email"
            autoFocus
          />
            <EditAndSaveInputField
            disabled={!editEnabled}
            variant="outlined"
            margin="normal"
            id="firstname"
            value={firstName}
            label="First Name"
            name="First Name"
            // onChange={(event) => setUsername(event.target.value)}
            autoComplete="First Name"
            autoFocus
          />
            <EditAndSaveInputField
            disabled={!editEnabled}
            variant="outlined"
            margin="normal"
            fullWidth
            id="lastname"
            label="Last Name"
            value={lastName}
            name="Last Name"
            // onChange={(event) => setUsername(event.target.value)}
            autoComplete="Last Name"
            autoFocus
          />
            <IconButton aria-label="save" onClick={(!editEnabled) ? editField : saveField}>
            {!editEnabled ? <EditIcon> edit </EditIcon>: <SaveIcon> save </SaveIcon>}
            </IconButton>
          </Container>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Button onClick={PinPointTest}> PINPOINT </Button>
      </TabPanel>
      <TabPanel value={value} index={2}>
        Custom Wordlists
      </TabPanel>
      <TabPanel value={value} index={3} >
        <Membership/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        Other
      </TabPanel>
    </div>
    </div>
  );
}

export default Profile;