import { makeStyles } from '@material-ui/styles';
import {t} from '../Basic';

const useStyles = makeStyles( theme => ({
    selectLabel: {
      width: "100%",
      padding: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })); 
  



const SelectLanguage = () => {

const classes = useStyles();

return (
    <div className={classes.selectLabel}>
        Please select your language in the top right
    </div>
  );
}

export default SelectLanguage;
