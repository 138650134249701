import axios from 'axios';
import {Container, Button, Typography, CircularProgress} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {t} from '../Basic';
import { StylesDictionary } from '../../TypeScriptInterfaces';
import { CardSelectInput, TypingInput } from '../Basic/TestingScreens';

  const styles:StylesDictionary = {
    correctAnswerButton: {
        backgroundColor: 'green'
    },
    incorrectAnswerButton: {
        backgroundColor: 'red'
    }
  }

    
interface CongjugatedProps {
    word: string
    partOfSpeech: string
    translation: string
}
interface UserAnswers {
    tense: string
    person: string
    number: string
    gender: string
}

interface ButtonStates {
    tense: boolean
    person: boolean
    number: boolean
    gender: boolean
}


const Conjugator = (props:any) => {


    const [allowAdvance, setAllowAdvance] = useState(false);
    const [inputType, setInputType] = useState('');


        return (
            <>
            {inputType === "typing" 
            ?
            <TypingInput filteredWords={props.filteredWords} isLoggedIn={props.isLoggedIn} allowAdvance={allowAdvance} setAllowAdvance={setAllowAdvance} setScore={props.setScore} score={props.score} questionType="conjugation" question="Parse" words={props.words} language={props.language}/>
            :
            <CardSelectInput filteredWords={props.filteredWords} isLoggedIn={props.isLoggedIn} allowAdvance={allowAdvance} setAllowAdvance={setAllowAdvance} setScore={props.setScore} score={props.score} questionType="conjugation" question="Parse" words={props.words} language={props.language}/>
            }
            </>
       )

}

export default Conjugator;