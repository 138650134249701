import {Container, IconButton, Box, Typography} from '@material-ui/core';
import {VolumeUp as VolumeUpIcon, Mic as MicrophoneIcon, PinDropSharp} from '@material-ui/icons';
import React, { useState, useEffect, useRef } from 'react';
import {default as currentUseState} from 'react-usestateref';
import { CircularProgress } from '@material-ui/core';
import '../../App.css'
import { ConsoleLogger } from '@aws-amplify/core';
import {AdjustingInterval} from './Utils';
import { normalize } from 'path';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect, disconnect } from 'extendable-media-recorder-wav-encoder';
import * as RECOIL from '../../Recoil';
import {useRecoilValue, useRecoilState} from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { speechrecognitionURL, texttospeechURL } from '../../Config';
import axios from 'axios'

// const speechCommands = require('@tensorflow-models/speech-commands');

// // When calling `create()`, you must provide the type of the audio input.
// // The two available options are `BROWSER_FFT` and `SOFT_FFT`.
// // - BROWSER_FFT uses the browser's native Fourier transform.
// // - SOFT_FFT uses JavaScript implementations of Fourier transform
// //   (not implemented yet).
// const recognizer = speechCommands.create('BROWSER_FFT');
// recognizer.ensureModelLoaded();

interface InitiateSpeakingProps {
    audio: string | any
    playAudio: boolean
}

interface ListeningSpeakingProps{
    language: String
    initialIndex: number
    isLoggedIn: boolean
}

interface UserSpeakingProps {

}


const recordAudio = (selectedLanguage:string, ws:WebSocket) => {

    const processMessage = (event:any) => {
        console.log("Recognised", JSON.parse(event.data)["recognised"])
    }


    // var clientID = "1"
    // var ws = new WebSocket(`ws://127.0.0.1:3003/ws/${selectedLanguage}/${clientID}`);
    // ws.onmessage = processMessage;

    return new Promise(resolve => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            var options = {
                mimeType: 'audio/wav'
            }
          const mediaRecorder = new MediaRecorder(stream, options);
          const audioChunks:any = [];
          mediaRecorder.addEventListener("dataavailable", event => {
            audioChunks.push(event.data);
            var reader = new FileReader();
            reader.readAsDataURL(event.data);
            reader.onloadend = function () {
                var base64String:any = reader.result
                var justTheString = base64String.substr(base64String.indexOf(', ') + 1)
                ws.send(justTheString)
            }
          });
  
          const start = () => {
            mediaRecorder.start();
          };
  
          const stop = () => {
            return new Promise(resolve => {
              mediaRecorder.addEventListener("stop", () => {
                const audioBlob = new Blob(audioChunks);
                const audioUrl = URL.createObjectURL(audioBlob);
                const audio = new Audio(audioUrl);
                const play = () => {
                    audio.play();
                };
  
                resolve({ audioBlob, audioUrl, play });
              });
  
              mediaRecorder.stop();
            });
          };
  
          resolve({ start, stop });
        });
    });
  };

export const UserSpeaking = (UserSpeakingProps:UserSpeakingProps) => {

    const registerConnects = async () => {
        await register(await connect())
        .catch((erorr) => {return});
    };

    const processMessage = (event:any) => {
        console.log("Recognised", JSON.parse(event.data)["recognised"])
    }
    const webSocket:any = useRef(null);

    useEffect(() => {
        registerConnects();
        var clientID = uuidv4().replaceAll("-", "")
        webSocket.current = new WebSocket(`wss://${speechrecognitionURL}:3002/ws/${selectedLanguage}/${clientID}`);
        webSocket.current.onmessage = processMessage;
        return () => webSocket.current.close();
    }, []);

    const [recordingState, setRecordingState, recordingStateRef] = currentUseState(false);
    const [progress, setProgress, progressRef] = currentUseState(0);
    const [recognisedWord, setRecognisedWord] = useState('')
    const [recognisedWordScore, setRecognisedWordScore] = useState(0)
    const selectedLanguage = useRecoilValue(RECOIL.selectedLanguage).toLowerCase();

    let recordingTimeMS:number = 1500;

    const normalise = (value:number) => (value - 0) * 100 / (recordingTimeMS - 0);
    var doWork = function() {
        setProgress((progress:any) => progressRef.current + (recordingTimeMS/100))
    };
    var doError = function() {
        console.warn('The drift exceeded the interval.');
    };
    var ticker = new AdjustingInterval(doWork, (recordingTimeMS/100), doError);

    const handleRecording = async () => {

        setRecordingState(true)
        //ticker.start();
        const recorder:any = await recordAudio(selectedLanguage, webSocket.current);
        recorder.start();

        setTimeout(async () => {
          const audio = await recorder.stop();
          setRecordingState(false)
          //ticker.stop();
          audio.play();
        }, recordingTimeMS);
    }

    const styles:any = {
        notRecording: {
        },
        isRecording: {
            color: 'green',
        },
        circularProgress:{
            color: 'lightblue'
        }
    }

    function CircularProgressWithContent(props:any) {
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress sx={styles.circularProgress} variant="determinate" value={normalise(progressRef.current)} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                    {props.content}
            </Box>
            </Box>
        );
    }

    // const testing = () => {
    //     fetch("http://localhost:3003/tts/arabic")
    //     .then((data) => console.log(data))
    // }

    return(
        <Container>
            <Typography>
            Say "hello"
            </Typography>
            <IconButton onClick={handleRecording} sx={!recordingStateRef.current ? styles.notRecording : styles.isRecording}>
                <CircularProgressWithContent content={<MicrophoneIcon/>}/>
            </IconButton>
        </Container>
    )
}

export const InitiateSpeaking = (InitiateSpeakingProps:InitiateSpeakingProps) => {

    const languageAudio = new Audio(InitiateSpeakingProps.audio);

    const playSound = () => {
        if(languageAudio !== undefined){
            languageAudio.play()
        }
    }

    useEffect(() => {
        playSound();
    }, []);

    return(
        <Container>
            {languageAudio ? 
                <IconButton aria-label="speak" onClick={playSound}>
                        <VolumeUpIcon />
                </IconButton>
            :
                <IconButton disabled aria-label="speak" onClick={playSound}>
                    <VolumeUpIcon />
                </IconButton>
            }
        </Container>
    )
}